import React from 'react';
import {Box, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import TabsBlock from '../../../../pieces/tabsBlock';
import {useCreateJobStyles} from '../createJobStyles';
import * as allConstants from '../../../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import {RepairDrainsPanel} from './panels/repairDrainsPanel';
import {CleanOutsPanel} from './panels/cleanOutsPanel';
import {RepairSewerPanel} from './panels/repairSewerPanel';
import {SpotRepairPanel} from './panels/spotRepairsPanel';
import {LinersPanel} from './panels/linersPanel';
import {useFormContext, useWatch} from 'react-hook-form';
import {inputTypes} from '../../../../pieces/inputs/formInput';
import Utils from '../../../../utils/utils';
import CustomFormBlock from '../../../../pieces/inputs/formBlock';

const useStyles = makeStyles(theme=> {
  return {
    width_2x_col: {
      '& .MuiInputBase-input, & .MuiSelect-select': {
        width: '240px !important'
      },
      '& .MuiInputBase-input': {
        width: '240px !important'
      }
    }
  };
});

export const Repair = (props)=> {
  const classes = {...useCreateJobStyles(), ...useStyles()};
  const FIELDS = allConstants.JOB_FIELDS.PLUMBING;

  const blockParent = `${props.parent}.${allConstants.JOB_FIELDS.PLUMBING.REPAIR.api_name}`;
  const formController = useFormContext();
  const values = formController.getValues();

  const invoiceRepair = useWatch({
    name: `${[blockParent]}.${[FIELDS.REPAIR_INVOICE_RIGHT_OF_WAY.api_name]}`,
    control: formController.control
  });

  const panels = {
    [FIELDS.REPAIR_DRAIN_LINE.api_name]: RepairDrainsPanel,
    [FIELDS.REPAIR_SEWER.api_name]: RepairSewerPanel,
    [FIELDS.CLEAN_OUTS.api_name]: CleanOutsPanel,
    [FIELDS.LINERS.api_name]: LinersPanel,
    [FIELDS.SPOT_REPAIR.api_name]: SpotRepairPanel,
  };

  const fields = [
    FIELDS.REPAIR_DRAIN_LINE, FIELDS.REPAIR_SEWER, FIELDS.CLEAN_OUTS, FIELDS.LINERS, FIELDS.SPOT_REPAIR
  ];

  const content = fields.map((f)=> {

    return {name: f.api_name, label: f.label, panel: ()=> panels[f.api_name](props)};
  }
  );

  const getCurrentValue = (field)=> {

    const currValues = formController.getValues();
    const val =  currValues?.[props.parent]?.[FIELDS.REPAIR.api_name]?.[field];
    return val;

  };

  return (<>
      <Box className={classes.tab_container}>
        <Box display={'flex'}>
          <TabsBlock content={content}/>
        </Box>
      </Box>
      <Box className={classes.tab_container} sx={{p: 0, pl: 0, columnGap: {xs: '10px', md: '20px'}, display: 'flex'}}>
        <Typography>Additional Options</Typography>
        <Box className={classes.width_2x_col} >
          <CustomFormBlock {...props} 
            inputType={inputTypes.singleSelect}
            options={Utils.getOptions(allConstants.ANSWER_OPTIONS, null)}
            field={FIELDS.REPAIR_INVOICE_RIGHT_OF_WAY}
            nested={true} 
            parent={`${blockParent}`}
            defaultValue={getCurrentValue(FIELDS.REPAIR_INVOICE_RIGHT_OF_WAY.api_name)}
          />
        </Box>
        {invoiceRepair === "Yes" && <>
          <CustomFormBlock
            {...props}
            labelSecondary={true}
            inputType={inputTypes.date}
            field={FIELDS.REPAIR_START_DATE}
            nested={true}
            parent={`${blockParent}`}
            defaultValue={getCurrentValue(FIELDS.REPAIR_START_DATE.api_name)}
          /> 
          <CustomFormBlock
            {...props}
            labelSecondary={true}
            inputType={inputTypes.date}
            field={FIELDS.REPAIR_COMPLETION_DATE}
            nested={true}
            parent={`${blockParent}`}
            defaultValue={getCurrentValue(FIELDS.REPAIR_COMPLETION_DATE.api_name)}
        /> 
        </>}
      </Box>
      <Box sx={{display: 'flex', marginLeft: '15px', marginTop: '-25px'}}>
        <Typography>Supporting Documents</Typography>
        <Typography sx={{ marginLeft: '15px', marginTop: '2px' }} variant={'body1'} color={STYLE_CONSTANTS.COLORS.globalRed}>Please upload any additional plan, diaggrams, or documents!</Typography>
      </Box>
    </>
  );
};

export default Repair;
