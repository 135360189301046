import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import {Box, Grid, Typography} from '@mui/material';
import CustomizedSwitch from '../../../../pieces/switch/switch';
import CustomFormBlock from '../../../../pieces/inputs/formBlock';
import {useCreateJobStyles} from '../createJobStyles';
import {CustomMediaQuery} from '../../../../hooks/customMediaQuery';
import Utils from '../../../../utils/utils';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import {CustomInput} from '../../../../pieces/inputs/customInputComponent';
import {inputTypes} from '../../../../pieces/inputs/formInput';
import {useFormContext, useWatch} from 'react-hook-form';
import Spacer from '../../../../pieces/spacer';

const useStyles = makeStyles(theme=> {
  return {
    fixture_col: {
      width: '130px'
    },
    table_header: {
      // height: '3.5em',
      background: STYLE_CONSTANTS.COLORS.backgroundMain,
      '& .MuiTypography-root': {
        fontSize: '12px'
      }

    },
    qty_col: {
      display: 'flex',
      justifyContent: 'center',
      width: '80px'
    },
    width_2x_col: {
      '& .MuiInputBase-input, & .MuiSelect-select': {
        width: '240px !important'
      },
      '& .MuiInputBase-input': {
        width: '240px !important'
      }
    }
  };
});

export const Repipe = (props)=> {
  const classes = {...useCreateJobStyles(), ...useStyles()};
  const FIELDS = allConstants.JOB_FIELDS.PLUMBING;
  const formController = useFormContext();
  const values = formController.getValues();
  const blockParent = `${props.parent}.${allConstants.JOB_FIELDS.PLUMBING.REPIPE.api_name}`;
  const [repipeWater, setRepipeWater] = useState(values?.[props.parent]?.[allConstants.JOB_FIELDS.PLUMBING.REPIPE.api_name]?.[FIELDS.REPIPE_FRESH_WATER.api_name] || false);
  const [repipeDrains, setRepipeDrains] = useState(values?.[props.parent]?.[allConstants.JOB_FIELDS.PLUMBING.REPIPE.api_name]?.[FIELDS.REPIPE_DRAINS.api_name] || false);

  const invoiceFreshWater = useWatch({
    name: `${[blockParent]}.${[FIELDS.REPIPE_FIXTURES_FRESH_WATER.api_name]}.${[FIELDS.FIXTURES_OPTIONS.INVOICE_RIGHT_OF_WAY.api_name]}`,
    control: formController.control
  });
  const invoiceDrains = useWatch({
    name: `${[blockParent]}.${[FIELDS.REPIPE_FIXTURES_DRAINS.api_name]}.${[FIELDS.FIXTURES_OPTIONS.INVOICE_RIGHT_OF_WAY.api_name]}`,
    control: formController.control
  });

  const getDefaultValue = (field, parent = null) => {
    if (parent) {

      return values?.[parent]?.[field];
    }
    return values?.[field];
  };

  //console.log('repipe water', repipeWater);
  const waterAction = (val)=> {
    setRepipeWater(val);
    formController.setValue(`${[blockParent]}.${[FIELDS.REPIPE_FRESH_WATER.api_name]}`, val, {shouldDirty: true});
  };

  const drainsAction = (val)=> {
    setRepipeDrains(val);
    formController.setValue(`${[blockParent]}.${[FIELDS.REPIPE_DRAINS.api_name]}`, val, {shouldDirty: true});
  };

  const fixtures = [
    FIELDS.FIXTURES.LAVATORIES,
    FIELDS.FIXTURES.SHOWER,
    FIELDS.FIXTURES.KITCHEN_SINK,
    FIELDS.FIXTURES.TUB_SHOWER,
    FIELDS.FIXTURES.LAUNDRY_SINK,
    FIELDS.FIXTURES.ICE_MAKERS,
    FIELDS.FIXTURES.TUB,
    FIELDS.FIXTURES.TOILET,
    FIELDS.FIXTURES.WASHERS,
    FIELDS.FIXTURES.GARBAGE_DISPOSER,
    FIELDS.FIXTURES.WATER_HEATER,
    FIELDS.FIXTURES.HOSE_BIBBS,
    FIELDS.FIXTURES.OTHER,
  ];


  // const countEquipment = (e, name)=> {
  //   const formValues = formController.getValues();
  //   let newValue = {};

  //   const fixtures = values?.[props.parent]?.[allConstants.JOB_FIELDS.PLUMBING.REPIPE.api_name];

  //   if(fixtures && Utils.objectHasProperty(fixtures, FIELDS.REPIPE_FIXTURES.api_name)) {

  //     newValue = formValues?.[props.parent]?.[allConstants.JOB_FIELDS.PLUMBING.REPIPE.api_name]?.[FIELDS.REPIPE_FIXTURES.api_name];
  //   }
  //   newValue = {...newValue, ...{[name]: e.target.value}};
  //   formController.setValue(`${blockParent}.${[FIELDS.REPIPE_FIXTURES.api_name]}`, newValue, {shouldDirty: true});
  // };

  const countEquipment = (e, name, repipeName)=> {
    const formValues = formController.getValues();
    let newValue = {};

    const fixtures = values?.[props.parent]?.[allConstants.JOB_FIELDS.PLUMBING.REPIPE.api_name];

    if(fixtures && Utils.objectHasProperty(fixtures, repipeName)) {

      newValue = formValues?.[props.parent]?.[allConstants.JOB_FIELDS.PLUMBING.REPIPE.api_name]?.[`${repipeName}`];
    }
    newValue = {...newValue, ...{[name]: e.target.value}};
    formController.setValue(`${blockParent}.${repipeName}`, newValue, {shouldDirty: true});
  };
  // console.log('equ count default', countEquipment(null, allConstants.JOB_FIELDS.PLUMBING.FIXTURES.LAVATORIES.api_name))

  // const getCurrentValue = (fixture)=> {
  //   const currValues = formController.getValues();
  //   if(!Utils.objectHasProperty(currValues?.[props.parent]?.[allConstants.JOB_FIELDS.PLUMBING.REPIPE.api_name], `${[FIELDS.REPIPE_FIXTURES.api_name]}`)) {
  //     return null;
  //   }
  //   const fixtures = currValues?.[props.parent]?.[allConstants.JOB_FIELDS.PLUMBING.REPIPE.api_name]?.[FIELDS.REPIPE_FIXTURES.api_name];
  //   if(!Utils.objectHasProperty(fixtures, fixture)) return null;
  //   return fixtures[fixture];
  // };

  const getCurrentValue = (fixture, repipeName)=> {
    const currValues = formController.getValues();
    if(!Utils.objectHasProperty(currValues?.[props.parent]?.[allConstants.JOB_FIELDS.PLUMBING.REPIPE.api_name], `${repipeName}`)) {
      return null;
    }
    const fixtures = currValues?.[props.parent]?.[allConstants.JOB_FIELDS.PLUMBING.REPIPE.api_name]?.[repipeName];
    if(!Utils.objectHasProperty(fixtures, fixture)) return null;

    return fixtures[fixture];
  };

  // const RowElement = ({field, defaultValue})=> {
  //   return(
  //     <Box display={'flex'}>
  //       <Box className={classes.row_wrapper}>
  //         <Box className={classes.fixture_col}>
  //           <Typography variant={'body1'} fontSize={'14'}>{field.label}</Typography>
  //         </Box>
  //         <Box className={classes.qty_col}>
  //           <CustomInput
  //             onChange={(e)=>countEquipment(e, field.api_name)}
  //             transform={(val)=> val.parseInt()}
  //             defaultValue={defaultValue}
  //             inputProps={{min: 0} }
  //             type="number"/>
  //         </Box>
  //       </Box>
  //     </Box>

  //   );
  // };

  const RowElement = ({field, defaultValue, repipeName})=> {
    return(
      <Box display={'flex'}>
        <Box className={classes.row_wrapper}>
          <Box className={classes.fixture_col}>
            <Typography variant={'body1'} fontSize={'14'}>{field.label}</Typography>
          </Box>
          <Box className={classes.qty_col}>
            <CustomInput
              onChange={(e)=>countEquipment(e, field.api_name, repipeName)}
              transform={(val)=> val.parseInt()}
              defaultValue={defaultValue}
              inputProps={{min: 0} }
              type="number"/>
          </Box>
        </Box>
      </Box>

    );
  };


  const getColumns = ()=> {
    const queries = CustomMediaQuery();
    let count = 1;
    if(queries.isSM) count = 3;
    if(queries.isMD) count = 4;
    if(queries.isXL || queries.isLG) count = 6;
    return Utils.chunkify(fixtures, count);
  };

  const renderColumns = getColumns();

  // console.log('DEFAULT FIXTUREs', getCurrentValue('lavatories'))

  // const values = props.getFormValues();
  // console.log('values', values)
  // console.log('block parent', blockParent)

  const getFixtures = (repipeTitle, repipeName, invoiceAnswer) => {
    return (
      <Grid item xs={12}>
        <Typography className={classes.block_header}>{repipeTitle}</Typography>
        <Typography variant={'body1'} color={STYLE_CONSTANTS.COLORS.globalRed}>Please fill out at least one Fixture field</Typography>
        <Box width={'100%'}>
          <Box className={clsx(classes.row_wrapper, classes.table_header)}>
            {renderColumns[0].map((field, idx)=> {
              return (
                <Box key={`${idx}-${field.api_name}`} display={'flex'}>
                  <Box className={clsx(classes.fixture_col)}>
                    <Typography variant={'body1'} fontSize={'12'}>Fixture</Typography>
                  </Box>
                  <Box className={classes.qty_col}>
                    <Typography variant={'body1'}>QTY</Typography>
                  </Box>
                </Box>
              );})}
          </Box>
          {renderColumns.map((row, index)=> {
            return (
              <Box className={classes.row_wrapper} key={index+JSON.stringify(row)}>
                {row.map((field, idx)=> {
                  return <RowElement
                    key={`${index}-${idx}-${field.api_name}`}
                    field={field}
                    defaultValue={getCurrentValue(field.api_name, repipeName)}
                    repipeName={`${repipeName}`}
                  />;})}
              </Box>
            );
          })}
          <Box className={classes.tab_container} sx={{mt: 1, p: 0, pl: 0, columnGap: {xs: '10px', md: '20px'}, display: 'flex', marginLeft: '-15px'}}>
            <Typography>Additional Options</Typography>
            <Box className={classes.width_2x_col} >
              <CustomFormBlock {...props} 
                field={FIELDS.FIXTURES_OPTIONS.MATERIAL_USED}
                nested={true}
                parent={`${blockParent}.${repipeName}`}
                defaultValue={getCurrentValue([FIELDS.FIXTURES_OPTIONS.MATERIAL_USED.api_name], repipeName)}
                renderLabel={true}
              />
            </Box>
            <CustomFormBlock {...props}
              field={FIELDS.FIXTURES_OPTIONS.ESTIMATE_PIPE_LENGTH}
              nested={true}
              parent={`${blockParent}.${repipeName}`}
              defaultValue={getCurrentValue([FIELDS.FIXTURES_OPTIONS.ESTIMATE_PIPE_LENGTH.api_name], repipeName)}
              renderLabel={true}
            />
            <Box className={classes.width_2x_col} >
              <CustomFormBlock {...props} 
                inputType={inputTypes.singleSelect}
                options={Utils.getOptions(allConstants.ANSWER_OPTIONS, null)}
                field={FIELDS.FIXTURES_OPTIONS.INVOICE_RIGHT_OF_WAY}
                nested={true} 
                parent={`${blockParent}.${repipeName}`}
                defaultValue={getCurrentValue([FIELDS.FIXTURES_OPTIONS.INVOICE_RIGHT_OF_WAY.api_name], repipeName)}
              />
            </Box>
            {invoiceAnswer === 'Yes' && <>
              <CustomFormBlock
                {...props}
                labelSecondary={true}
                inputType={inputTypes.date}
                field={FIELDS.FIXTURES_OPTIONS.START_DATE}
                nested={true}
                parent={`${blockParent}.${repipeName}`}
                defaultValue={getCurrentValue([FIELDS.FIXTURES_OPTIONS.START_DATE.api_name], repipeName)}
              /> 
              <CustomFormBlock
                {...props}
                labelSecondary={true}
                inputType={inputTypes.date}
                field={FIELDS.FIXTURES_OPTIONS.COMPLETION_DATE}
                nested={true}
                parent={`${blockParent}.${repipeName}`}
                defaultValue={getCurrentValue([FIELDS.FIXTURES_OPTIONS.COMPLETION_DATE.api_name], repipeName)}
              />
            </>}
          </Box>
          <Box width={'100%'} sx={{display: 'flex', marginTop: '-25px'}}>
              <Typography>Supporting Documents</Typography>
              <Typography sx={{ marginLeft: '15px', marginTop: '2px' }} variant={'body1'} color={STYLE_CONSTANTS.COLORS.globalRed}>Please upload any additional plan, diaggrams, or documents!</Typography>
          </Box>
        </Box>
      </Grid>
    );
  }

  return (
    <Box className={classes.tab_container}>
      <Grid container>
        <Grid item xs={12} md={4} display={'flex'} flexDirection={'column'}>
          <CustomizedSwitch label={FIELDS.REPIPE_FRESH_WATER.label}
            action={waterAction}
            defaultChecked={repipeWater}
            // checked={values?.[blockParent]?.[FIELDS.REPIPE_FRESH_WATER.api_name]}
          />
          <CustomizedSwitch label={FIELDS.REPIPE_DRAINS.label}
            action={drainsAction}
            defaultChecked={repipeDrains}
            //checked={values?.[blockParent]?.[FIELDS.REPIPE_DRAINS.api_name]}
          />
        </Grid>
        <Grid item xs={12} md={6} display={'flex'} flexDirection={{xs: 'column', sm: 'row'}}>
          <Box sx={{
            // width:{sx: '100%', sm: '150px'},
            marginRight: theme=>theme.spacing(2),
            paddingTop: '5px',
          }}>
            <Typography component={'p'} fontSize={{xs: '14px', md: '16px'}}>{allConstants.JOB_FIELDS.GENERAL.SCOPE.label}</Typography>
          </Box>
          <CustomFormBlock
            {...props}
            renderLabel={false}
            multiline={true}
            field={allConstants.JOB_FIELDS.GENERAL.SCOPE}
            nested={true}
            parent={blockParent}
            defaultValue={values?.[`${blockParent}`]?.[allConstants.JOB_FIELDS.GENERAL.SCOPE.api_name]}
            fullWidth/>
        </Grid>
        {(repipeWater) && getFixtures('Fixtures - Fresh Water', `${[FIELDS.REPIPE_FIXTURES_FRESH_WATER.api_name]}`, invoiceFreshWater)}
        <Spacer y={1} />
        {(repipeDrains) && getFixtures('Fixtures - Drains', `${[FIELDS.REPIPE_FIXTURES_DRAINS.api_name]}`, invoiceDrains)}

        {/* {(repipeWater || repipeDrains) &&
        <Grid item xs={12}>
          <Typography component={'p'} className={classes.block_header}>Fixtures</Typography>
          <Typography variant={'body1'} color={STYLE_CONSTANTS.COLORS.globalRed}>Please fill out at least one Fixture field</Typography>
          <Box width={'100%'}>
            <Box className={clsx(classes.row_wrapper, classes.table_header)}>
              {renderColumns[0].map((field, idx)=> {
                return (
                  <Box key={`${idx}-${field.api_name}`} display={'flex'}>
                    <Box className={clsx(classes.fixture_col)}>
                      <Typography variant={'body1'} fontSize={'12'}>Fixture</Typography>
                    </Box>
                    <Box className={classes.qty_col}>
                      <Typography variant={'body1'}>QTY</Typography>
                    </Box>
                  </Box>
                );})}
            </Box>

            {renderColumns.map((row, index)=> {
              return (
                <Box className={classes.row_wrapper} key={index+JSON.stringify(row)}>
                  {row.map((field, idx)=> {
                    return <RowElement
                      key={`${index}-${idx}-${field.api_name}`}
                      field={field}
                      defaultValue={getCurrentValue(field.api_name)}
                    />;})}
                </Box>
              );
            })}
          </Box>
        </Grid>} */}
        {/* <Grid item xs={12}>
          <Typography component={'p'} className={classes.block_header}>Fixtures - Fresh Water</Typography>
          <Typography variant={'body1'} color={STYLE_CONSTANTS.COLORS.globalRed}>Please fill out at least one Fixture field</Typography>
          <Box width={'100%'}>
            <Box className={clsx(classes.row_wrapper, classes.table_header)}>
              {renderColumns[0].map((field, idx)=> {
                return (
                  <Box key={`${idx}-${field.api_name}`} display={'flex'}>
                    <Box className={clsx(classes.fixture_col)}>
                      <Typography variant={'body1'} fontSize={'12'}>Fixture</Typography>
                    </Box>
                    <Box className={classes.qty_col}>
                      <Typography variant={'body1'}>QTY</Typography>
                    </Box>
                  </Box>
                );})}
            </Box>
            {renderColumns.map((row, index)=> {
              return (
                <Box className={classes.row_wrapper} key={index+JSON.stringify(row)}>
                  {row.map((field, idx)=> {
                    return <RowElement
                      key={`${index}-${idx}-${field.api_name}`}
                      field={field}
                      defaultValue={getCurrentValue(field.api_name)}
                      repipeName={`${[FIELDS.REPIPE_FIXTURES_FRESH_WATER.api_name]}`}
                    />;})}
                </Box>
              );
            })}
            <Box className={classes.tab_container} sx={{p: 3, pl: 0, columnGap: {xs: '10px', md: '20px'}, display: 'flex'}}>
              <CustomFormBlock{...props}
                field={FIELDS.FIXTURES_OPTIONS.MATERIAL_USED}
                nested={true}
                parent={`${blockParent}.${[FIELDS.REPIPE_FIXTURES_FRESH_WATER.api_name]}`}
                defaultValue={getFieldValue(FIELDS.FIXTURES_OPTIONS.MATERIAL_USED.api_name)}
                renderLabel={true}
              />
              <CustomFormBlock{...props}
                field={FIELDS.FIXTURES_OPTIONS.ESTIMATE_PIPE_LENGTH}
                nested={true}
                parent={`${blockParent}.${[FIELDS.REPIPE_FIXTURES_FRESH_WATER.api_name]}`}
                defaultValue={getFieldValue(FIELDS.FIXTURES_OPTIONS.ESTIMATE_PIPE_LENGTH.api_name)}
                renderLabel={true}
              />
            </Box>
          </Box>
        </Grid>}
        {(repipeDrains) &&
        <Grid item xs={12}>
          <Typography component={'p'} className={classes.block_header}>Fixtures - Drains</Typography>
          <Typography variant={'body1'} color={STYLE_CONSTANTS.COLORS.globalRed}>Please fill out at least one Fixture field</Typography>
          <Box width={'100%'}>
            <Box className={clsx(classes.row_wrapper, classes.table_header)}>
              {renderColumns[0].map((field, idx)=> {
                return (
                  <Box key={`${idx}-${field.api_name}`} display={'flex'}>
                    <Box className={clsx(classes.fixture_col)}>
                      <Typography variant={'body1'} fontSize={'12'}>Fixture</Typography>
                    </Box>
                    <Box className={classes.qty_col}>
                      <Typography variant={'body1'}>QTY</Typography>
                    </Box>
                  </Box>
                );})}
            </Box>

            {renderColumns.map((row, index)=> {
              return (
                <Box className={classes.row_wrapper} key={index+JSON.stringify(row)}>
                  {row.map((field, idx)=> {
                    return <RowElement
                      key={`${index}-${idx}-${field.api_name}`}
                      field={field}
                      defaultValue={getCurrentValue(field.api_name)}
                      repipeName={`${[FIELDS.REPIPE_FIXTURES_DRAINS.api_name]}`}
                    />;})}
                </Box>
              );
            })}
            <Box className={classes.tab_container} sx={{p: 3, pl: 0, columnGap: {xs: '10px', md: '20px'}, display: 'flex'}}>
              <CustomFormBlock{...props}
                field={FIELDS.FIXTURES_OPTIONS.MATERIAL_USED}
                nested={true}
                parent={`${blockParent}.${[FIELDS.REPIPE_FIXTURES_DRAINS.api_name]}`}
                defaultValue={getFieldValue(FIELDS.FIXTURES_OPTIONS.MATERIAL_USED.api_name)}
                renderLabel={true}
              />
              <CustomFormBlock{...props}
                field={FIELDS.FIXTURES_OPTIONS.ESTIMATE_PIPE_LENGTH}
                nested={true}
                parent={`${blockParent}.${[FIELDS.REPIPE_FIXTURES_DRAINS.api_name]}`}
                defaultValue={getFieldValue(FIELDS.FIXTURES_OPTIONS.ESTIMATE_PIPE_LENGTH.api_name)}
                renderLabel={true}
              />
            </Box>
          </Box>
        </Grid>} */}

      </Grid>
    </Box>
  );
};

Repipe.propTypes = {
  parent: PropTypes.string.isRequired,
  field: PropTypes.shape({
    api_name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }).isRequired,
  defaultValue: PropTypes

};

export default Repipe;
