import React, {useState} from 'react';
import PropTypes from 'prop-types';
import base_url from '../../baseUrls';
import * as allConstants from '../../constants/allConstants';
import MyPaginatedGrid from '../../pieces/grids/paginatedGridWrapper';
import ColumnDefinition from '../../pieces/grids/columnGenerator';
import {NewPermissionGate} from '../../permissions/permissionGate';
//import {MaximizableCellRenderer} from '../../pieces/grids/MaximizableCellRenderer';
import ApiUtils from '../../utils/apiUtils';
import {useAutocompleteOptions} from '../../hooks/useAutompleteOptions';
import Utils from '../../utils/utils';
import {REBATE_ELEMENTS, rebateRules} from '../../permissions/rebateAccessControl';

const AllRebatesGrid = (props) => {
  const viewController = props.viewController;
 
  let columnDefs = [
    new ColumnDefinition({field: allConstants.REBATE_FIELDS.ID, hide: true}),
    new ColumnDefinition({
      field: allConstants.REBATE_FIELDS.REBATE_NAME,
      lockPinned: true,
      pinned: 'left',
      checkboxSelection: true,
      width: 600,
      wrapText: true
    }),
    new ColumnDefinition({
      field: allConstants.REBATE_FIELDS.DATE_EXPIRED,
      filter: 'agDateColumnFilter',
      valueGetter: (({data}) => {
        const val = data?.[allConstants.REBATE_FIELDS.DATE_EXPIRED.api_name];
        if (val) return Utils.formatIsoDateString(val, {date: true});
        return '';
      }),
    }),
    new ColumnDefinition({
      field: allConstants.REBATE_FIELDS.CREATDE_BY,
    }),
  ]

  const editRebateHandler = (rebateData)=> {
    props.editRebateHandler(rebateData);
  };

  const deleteRebateHandler = (rebateData)=> {
    props.deleteRebateHandler(rebateData);
  };

  const getContextMenuItems = (params)=> {
    if(!params.node) return [];

    const standardActions = [
      {
        name:  'Export Selected (.xlsx)',
        action: () => params.api.exportDataAsExcel(
          {onlySelected: true}
        )
      },
      'copy',
    ];

    const actions = {
      edit: {
        name: 'Edit',
        disabled: viewController.selectedMultipleRows(),
        action: () => {
          editRebateHandler(params?.node?.data);
        },
      },
      delete: {
        name: 'Delete',
        cssClasses: ['redFont', 'bold'],
        disabled: viewController.selectedMultipleRows(),
        action: () => {
          deleteRebateHandler(params?.node?.data);
        },
      },
    };

    let allowedActions = [];
    if(NewPermissionGate({
      user: viewController.user,
      elementPermissions: rebateRules[REBATE_ELEMENTS.editRebate],
    })) {
      allowedActions.push(actions.edit);
    }

    allowedActions = [...allowedActions, ...standardActions];
    return allowedActions;

  };

  const ls_name = 'rebates_grid';

  return (
    <MyPaginatedGrid columnDefs={columnDefs}
      masterDetail={true}
      //components={components}
      contextMenu={getContextMenuItems}
      ls_name={ls_name}
      registerGrid={viewController.setGridToParent}
      registerTempGrid={viewController.setTempGridToParent} 
      fetchParams={{url: `${base_url.api}rebates/`, respKey:'rebates'}}
      companyId={viewController.companyId}
      onFetchFail={viewController.onFetchFail}
      pagination={props.pagination}
    />
  );
};

export default AllRebatesGrid;


