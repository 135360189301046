const CO_OR_NEW = {
  CHANGEOUT: {display: 'Changeout', value: 'Changeout', showOrder: 0, def: false},
  CONVERSION: {display: 'Conversion', value: 'Conversion', showOrder: 1, def: false},
  NEW: {display: 'New', value: 'New', showOrder: 2, def: false},
  RELOCATE: {display: 'Relocate', value: 'Relocate', showOrder: 3, def: false},
};
const ACTION_TRADITIONAL = {
  CHANGEOUT: {display: 'Changeout', value: 'Changeout', showOrder: 0, def: false},
  NEW: {display: 'New', value: 'New', showOrder: 1, def: false},
  RELOCATE: {display: 'Relocate', value: 'Relocate', showOrder: 2, def: false},
  TANKLESS_TO_TANKED: {display: 'Tankless to Tanked', value: 'Tankless to Tanked', showOrder: 3, def: false},
};

const ACTION_TANKLESS = {
  CHANGEOUT: {display: 'Changeout', value: 'Changeout', showOrder: 0, def: false},
  NEW: {display: 'New', value: 'New', showOrder: 1, def: false},
  RELOCATE: {display: 'Relocate', value: 'Relocate', showOrder: 2, def: false},
  TANKED_TO_TANKLESS: {display: 'Tanked to TankLess', value: 'Tanked to TankLess', showOrder: 3, def: false},
};

export {CO_OR_NEW, ACTION_TRADITIONAL, ACTION_TANKLESS};