const STATE_FIELDS = {
  ID: {api_name: 'id', required: false, label: 'id', display_label: 'id'},
  NAME: {api_name: 'name', required: true, label: 'State name', display_label: 'state name'},
  REGIONS: {api_name: 'regions', required: false, label: 'Regions', display_label: 'Regions'},
};

const REGION_FIELDS = {
  ID: {api_name: '_id', required: false, label: 'id', display_label: 'id'},
  NAME: {api_name: 'name', required: true, label: 'Region name', display_label: 'region name'},
};

export {STATE_FIELDS, REGION_FIELDS};
