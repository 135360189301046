import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Box, CircularProgress,  Typography} from '@mui/material';
import * as allConstants from '../../../../../constants/allConstants';
import AllJobsGrid from '../../../allJobs/allJobsGrid';
import DisplayListsPageWrapper from '../../../../wrappers/displayListsPageWrapper';
import ListPageHeader from '../../../../../pieces/listPageHeader';
import usePagination from '../../../../../hooks/usePagination';
import {JobJSON, DevHandleAlerts} from '../../../allJobs/devJobView';
import ModalWrapper from '../../../../../pieces/modals/modalWrapper';
import JobDetailView from '../../../jobDetails/views/jobDetailView';
import {jobApiTypes} from '../../../helpers/jobGridOptions';
import mergeProps from 'merge-props';
import {useGridViewState} from '../../../../../hooks/useGridViewState';
import Utils from '../../../../../utils/utils';
import {useSelector} from 'react-redux';
import {companyAndUserUtils} from '../../../../../utils/companyAndUserUtils';
import {IPERMIT_ROLES} from '../../../../../constants/roles';
import {useFilter, GridWithFilterWrapper} from '../../../../../hooks/useFilter';
import {jobFilters} from '../../../allJobs/jobExtendedFilters';
import useViewMenu, {MenuOption} from '../../../../../hooks/useViewMenu';
import {ListViewHeader} from '../../../components/listViewHeader';
import base_url, {urlSearchParams} from '../../../../../baseUrls';
import {isContractor, isImpersonating} from '../../../../../permissions/utils';
import axios from 'axios';
import ApiUtils from '../../../../../utils/apiUtils';
import {useGotIt} from '../../../../../hooks/useGotIt';

const AllPermitJobsView = () => {
  const viewController = useGridViewState();
  const navigate = useNavigate();
  const initialSelectedJob = Utils.getUrlParameter('job');
  const [selectedJob, setSelectedJob] = useState(initialSelectedJob);
  const [isLoading, setIsLoading ] = useState(false);
  const [showDevJobDetails, setShowDevJobDetails] = useState(false);
  const [showDevZohoAlertHandler, setshowDevZohoAlertHandler] = useState(false);
  const pagination = usePagination();
  const [searchParams, setSearchParams] = useSearchParams();
  const [allowScrollModal, setAllowScrollModal] = useState(true);
  const company = useSelector(state => state.company);
  const user = useSelector(state=> state.user);
  const userRole = user[allConstants.USER_FIELDS.ROLE.api_name];
  const isHers = Object.values(IPERMIT_ROLES).includes(userRole)|| companyAndUserUtils.isHERSCompany(company);
  const isEuc =  Object.values(IPERMIT_ROLES).includes(userRole) || companyAndUserUtils.isEUCCompany(company);
  const isTestingOnly =companyAndUserUtils.isTestingOnlyCompany(company);
  const gotItPopup = useGotIt();
  const filterFields = jobFilters.getFilters(user);

  const extendedFilters = useFilter({fields: filterFields, width: 230, collection: 'jobs'});

  const getMenuItems = ()=> {
    const items = [new MenuOption(jobApiTypes.all, 'All Jobs')];
    if(!isTestingOnly && (isHers || isEuc)) {
      items.push(new MenuOption(jobApiTypes.permits, 'Permit Jobs'));
    }
    if((isHers && !isTestingOnly)||isEuc) {
      items.push( new MenuOption(jobApiTypes.hers, 'HERS Jobs'));
    }
    if(isEuc) {
      items.push( new MenuOption(jobApiTypes.euc, 'EUC Jobs'));
    }
    items.push( new MenuOption(jobApiTypes.inspections, 'Inspections'));
    items.push( new MenuOption(jobApiTypes.drafts, 'Drafts'));
    items.push( new MenuOption(jobApiTypes.report, 'Report for Export'));
    return items;
  };
  const JobGridEnum = getMenuItems();

  const menuController = useViewMenu({items: JobGridEnum});

  const handleShowJobClose = () => {
    setSelectedJob(undefined);
  };

  const handleClick = (event) => {
    menuController.setAnchorEl(event.currentTarget);
  };

  const viewJobHandler = (id)=> {

    // Thanh 01/01/24
    //console.log('location - from prop');
    //console.log(location);

    const url = `${location.origin}${location.pathname}/${id}${location.search}`; 

    //alert(url);
    //alert(location);

    //console.log('url');
    //console.log(url);
  
    window.open(url, '_blank');
    
    //navigate(`${id}${location.search}`);
  };

  const devViewJobOpen = (id)=> {
    setSelectedJob(id);
    setShowDevJobDetails(true);
  };
  const devAlertJobOpen = (id)=> {
    setSelectedJob(id);
    setshowDevZohoAlertHandler(true);
  };
  const devViewJobClose = (id)=> {
    setSelectedJob(null);
    if(showDevJobDetails) {
      setShowDevJobDetails(false);
    } else if(showDevZohoAlertHandler) {
      setshowDevZohoAlertHandler(false);
    }

  };

  const gridProps = {
    devViewJobHandler: devViewJobOpen,
    crmAlertHandler: devAlertJobOpen,
    viewJobHandler: viewJobHandler,
    pagination: pagination,
    viewController: viewController,

  };

  const changeView = () => {

    // Than 01/01/24
    //console.log('inside permit Jobs change view => search param');
    //console.log(searchParams);

    const job = searchParams.get('job');

    //console.log(job);

    if (job) { viewJobHandler(job); }
  };

  const getFilterParamsFromURL = ()=> {
    if(searchParams.get([allConstants.JOB_FIELDS.PERMIT_STATUS.api_name])) {
      return [allConstants.JOB_FIELDS.PERMIT_STATUS.api_name, searchParams.get([allConstants.JOB_FIELDS.PERMIT_STATUS.api_name])];
    } else if(searchParams.get([allConstants.JOB_FIELDS.HERS_STATUS.api_name])) {
      return [allConstants.JOB_FIELDS.HERS_STATUS.api_name, searchParams.get([allConstants.JOB_FIELDS.HERS_STATUS.api_name])];
    }
    return undefined;
  };

  const defFilterParams = getFilterParamsFromURL();


  useEffect(()=> {
    if(selectedJob) {

      // Thanh 01/01/24
      //console.log('select permit job in permit jobs');

      setSearchParams(Utils.createQueryParam(location, {job: selectedJob}));
    } else {

      // Thanh 01/01/24
      //console.log('no job selected in permit job');

      searchParams.delete('job');
      searchParams.delete('tab');
      setSearchParams(searchParams);
    }
  }, [selectedJob]);

  useEffect(() => {
    changeView();
  }, []);

  useEffect(()=> {
    searchParams.set(urlSearchParams.view, JobGridEnum[menuController.viewGrid].value);
    if(menuController?.title?.toLowerCase() !== jobApiTypes.inspections){
      searchParams.delete(urlSearchParams.failedInspections);

    }
    setSearchParams(searchParams);
  }, [menuController.title]);


  const toggleFailedInspections = (event) => {
    const failedInspections = searchParams.get(urlSearchParams.failedInspections);
    if (failedInspections) {
      searchParams.delete(urlSearchParams.failedInspections);
    } else {
      searchParams.set(urlSearchParams.failedInspections, true);
    }
    setSearchParams(searchParams);
  };

  const shouldHideFilters = ()=> {

    if(!isContractor(userRole) && !isImpersonating(userRole, viewController.companyId)) {
      return false;
    }
    // temporary hide advanced inspections filters for contractors
    return menuController.viewGrid === 4;
  };


  const getHeaderComponent = ()=> {
    let title = menuController.title;

    if(title?.toLowerCase() === jobApiTypes.inspections) {
      //modify title for inspections
      const isFailedOnly = searchParams.get(urlSearchParams.failedInspections);
      title = `${isFailedOnly ? 'Failed ' : 'All'} ${title}`;
    }
    if(pagination.totalItemsCount) {
      title = `${title} (${pagination.totalItemsCount})`;
    }

    return (
      <ListViewHeader title={title}
        gridEnum={JobGridEnum}
        pagination={pagination}
        handleClick={handleClick}
        menuController={menuController}
        extendedFilters={shouldHideFilters() ? null : extendedFilters}
        viewController={viewController}
      />
    );
  };

  const refreshGrid = () => {
    viewController.refreshGrid();
  }
  const getNewTenantJobs = async () => {

    setIsLoading(true);

    const url = `${base_url.api}companies/get-tenant-jobs-manually`;
    
    let requestParams = { tenantId: company.serviceTitan?.tenantId ?? 0,
                          id: 0 };

    const authToken = await ApiUtils.getAccessToken(); 

    let axiosConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authToken,
      }
    };
    await axios.post(url, JSON.stringify(requestParams), axiosConfig);
    
    setIsLoading(false);
    gotItPopup.setShow(`Please wait for a minute or two and click on "Refresh Grid" button to see new jobs`);


  }
  const getAdditionalButtons = ()=> {

    let buttons = [];

    if(menuController.title?.toLowerCase() === jobApiTypes.inspections) {
      buttons.push(
        {
          key: 1,
          label: `${searchParams.get(urlSearchParams.failedInspections) ? 'All Inspections' : 'Failed Only' }`,
          variant: 'outlined',
          color: `${searchParams.get(urlSearchParams.failedInspections) ? 'primary' : 'secondary' }`,
          onClick: toggleFailedInspections,
        }
      );
    }

    //console.log('company info - permit view');
    //console.log(company);

    // if (company.serviceTitan?.tenantId != null) {
    //   buttons.push(
    //     {
    //       key: 2,
    //       label: `Get New Jobs`,
    //       variant: 'outlined',
    //       color: 'primary',
    //       onClick: getNewTenantJobs,
    //     },
    //   );  
    // };
    buttons.push(
      {
        key: 3,
        label: `Refresh Grid`,
        variant: 'outlined',
        color: 'primary',
        onClick: refreshGrid,
      }
    )

    return buttons;

    // if(menuController.title?.toLowerCase() === jobApiTypes.inspections) {
    //   return [
    //     {
    //       label: `${searchParams.get(urlSearchParams.failedInspections) ? 'All Inspections' : 'Failed Only' }`,
    //       variant: 'outlined',
    //       color: `${searchParams.get(urlSearchParams.failedInspections) ? 'primary' : 'secondary' }`,
    //       onClick: toggleFailedInspections,
    //     }
    //   ];
    // }
    // return null;
  };

  const headerComponent = <ListPageHeader headComponent = {getHeaderComponent()}
    totalItems={pagination.totalItemsCount}
    addHandler={()=>{navigate(allConstants.PATH.CREATE_JOB);}}
    showAddButton ={false}
    additionalButtons={getAdditionalButtons()}

  >
    {pagination.ItemsController}
  </ListPageHeader>;

  const currGridProps = mergeProps(gridProps, {gridType: JobGridEnum[menuController.viewGrid].value});

  return (
    // viewController.RenderElement(
    //   <>
    //     <NavBar/>
    //
    //     <ThemeProvider theme={myTheme}>
    //       <Box className={clsx(classes.page)}>
    <>
        {gotItPopup.PopUp()}
        {isLoading && <Box display='flex' justifyContent='center' alignItems='center'>
              <CircularProgress variant="indeterminate"  color="success" />
              <Typography color="red" variant="h6">
                  Please wait!
              </Typography>                
            </Box>}
      {selectedJob && showDevJobDetails && <ModalWrapper open={showDevJobDetails} modalName={'job-json'}
        handleClose={devViewJobClose} width={'90%'}
        preventScroll={false}
      >
        <JobJSON jobId={selectedJob}/>
      </ModalWrapper>}
      {selectedJob && showDevZohoAlertHandler && <ModalWrapper open={showDevZohoAlertHandler} modalName={'crm-alerts'}
        handleClose={devViewJobClose} width={'90%'}
        preventScroll={false}
      >
        <DevHandleAlerts jobId={selectedJob} closeModal={devViewJobClose}/>
      </ModalWrapper>}
      {selectedJob && !showDevJobDetails && !showDevZohoAlertHandler && <ModalWrapper
        open={true} modalName={'job-details'}
        customStyle = {{minHeight: '96%'}}
        preventScroll={!allowScrollModal}
        handleClose={handleShowJobClose} width={'90%'}
      >
        <JobDetailView jobId={selectedJob} closeModal={handleShowJobClose}
          setModalScrollable={(allow)=> {setAllowScrollModal(allow);}}/>
      </ModalWrapper>}
      <DisplayListsPageWrapper
        headerComponent={headerComponent}>
        <GridWithFilterWrapper show={extendedFilters.show} filterPanel={extendedFilters.filterPanel}>
          <AllJobsGrid key={JobGridEnum[menuController.viewGrid].value}
            {...currGridProps}
            defFilterParams={defFilterParams}
            additionalFilters={extendedFilters.applyFilters}

          />
        </GridWithFilterWrapper>
        {pagination.PageController}
      </DisplayListsPageWrapper>
    </>
    //       </Box>
    //     </ThemeProvider>
    //   </>
    // )

  );
};
export default AllPermitJobsView;
