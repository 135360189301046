const STATE_ELEMENTS = {
  editState: 'editState',
  createState: 'createState',
  viewState: 'viewState',
};

const statePolicies = {
  modify: 'state.modify',
};

const stateRules = Object.freeze({
  [STATE_ELEMENTS.createState]: {
    policyName: statePolicies.modify,
    contractorAccess: false,
  },
  [STATE_ELEMENTS.editState]: {
    policyName: statePolicies.modify,
    contractorAccess: false,
  },
  [STATE_ELEMENTS.viewState]:{
    policyName: undefined,  //unrescrticted access to view city
    contractorAccess: false,
  },
});

export { STATE_ELEMENTS, stateRules}