const REBATE_ELEMENTS = {
  editRebate: 'editRebate',
  createRebate: 'createRebate',
  viewRebate: 'viewRebate',
};

const rebatePolicies = {
  modify: 'rebate.modify',
};

const rebateRules = Object.freeze({
  [REBATE_ELEMENTS.createRebate]: {
    policyName: rebatePolicies.modify,
    contractorAccess: false,
  },
  [REBATE_ELEMENTS.editRebate]: {
    policyName: rebatePolicies.modify,
    contractorAccess: false,
  },
  [REBATE_ELEMENTS.viewRebate]:{
    policyName: undefined,  //unrescrticted access to view city
    contractorAccess: false,
  },
});

export { REBATE_ELEMENTS, rebateRules }