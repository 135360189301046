import Utils from '../utils';
import tokenValidator from './tokenValidator';
import {VALID_FILE_TYPES} from '../../constants/allConstants';
import axios from 'axios';
import base_url from '../../baseUrls';

class Validator{
  tokensAreValid= async (tokenPair)=> {
    const result = await tokenValidator(tokenPair);
    return !Utils.objectHasProperty(result, 'errors');
  }
  /**
   *
   * @param {string} email
   * @param {boolean} nullIsValid
   * @return {boolean}
   */
  isValidEmail = (email, nullIsValid=false)=> {
    if(nullIsValid && (!email || email.length === 0)) return true;
    const regex = new RegExp(/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regex.test(email);
  }
  isValidPhoneNumber = (number)=> {
    if(!number || number.length === 0) return true;
    const regex = new RegExp(/^[0-9]{10}/);
    return regex.test(number);
  }
  isValidCurrencyFormat = (amount)=> {
    const regex = new RegExp(/^(\$)?(([1-9]\d{0,2}(\\,\d{3})*)|([1-9]\d*)|(0))(\.\d{2})?$/);
    return regex.test(amount);
  }
  isNumber = (number) => {
    const regex = new RegExp(/^\d+$/);
    return regex.test(number);
  }
  isValidFileType = (fileType) => {
    let validFile = false;
    Object.values(VALID_FILE_TYPES).forEach(type => {
      if (fileType === type) {
        validFile = true;
      }
    });
    return validFile;
  }
  isValidProductName = (string)=> {
    const regex = new RegExp(/&/gm);
    return !regex.test(string);
  }
  isValidTagName = (string)=> {
    const regex = new RegExp(/&/gm);
    return !regex.test(string);
  }
  isValidStateName = (string)=> {
    const regex = new RegExp(/&/gm);
    return !regex.test(string);
  }
  isValidRebateName = (string)=> {
    const regex = new RegExp(/&/gm);
    return !regex.test(string);
  }
  isStringNotEmply = (string) => {
    return string && string.length !== 0;
  }

  checkIfCompanyExists = async (params)=> {
    let exists = false;
    try {
      const response = await axios.get(`${base_url.api}companies/exists`, {params: params});
      return !response.data?.data?.exists || false;
    } catch (e) {}
    return new Promise((resolve) => {
      resolve(!exists);
    });
  }

  checkIfEmailExists = async (value, currentEmail=null)=> {
    let exists = false;
    if(currentEmail && currentEmail === value) return true;
    if(!value || value.length === 0) return false;
    try {
      const requestParams = {
        email: value
      };
      const response = await axios.get(`${base_url.api}users/exists`, {params: requestParams});
      return !response.data?.data?.exists || false;
    } catch (e) {
    }

    return new Promise((resolve) => {
      resolve(!exists);
    });
  }

  validateMultipleEmails = (emails)=> {

    const normalizedEmails = emails.replace(/\s/g, '');
    //regexp for comma separated emails
    const regex = new RegExp(/[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}(,[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})*$/);
    if(normalizedEmails.length === 0) {
      return true;
    } else if(!regex.test(normalizedEmails)){
      //return false if email are not comma separated
      return false;
    }
    //split emails by comma

    const emailArray = emails.split(',');
    let valid = true;
    emailArray.forEach(email => {
      //normalize email
      const normalizedCurrentEmail = email.replace(/\s/g, '');
      if(normalizedCurrentEmail.length === 0) valid = false;
      if (!this.isValidEmail(normalizedCurrentEmail)) {
        valid = false;
      }
    });
    return valid;
  }



}

const validator = new Validator();
export default validator;
