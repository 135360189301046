import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ThemeProvider, Box} from '@mui/material';
import NavBar from '../../pieces/navbar/navbar';
import * as allConstants from '../../constants/allConstants';
import {NewPermissionGate} from '../../permissions/permissionGate';
import myTheme from '../../styles/myStyles';
import DisplayListsPageWrapper from '../wrappers/displayListsPageWrapper';
import ListPageHeader from '../../pieces/listPageHeader';
import {ListViewHeader} from '../jobs/components/listViewHeader';
import usePagination from '../../hooks/usePagination';
import {useGridViewState} from '../../hooks/useGridViewState';
import {globalUseClasses} from '../../styles/globalClasses';
import {ratersPageRules, RATER_ELEMENTS} from '../../permissions/raterPageAccessControl';
import AllRatersGrid from './allRatersGrid';

const AllRatersView = () => {
  const viewController = useGridViewState();
  const classes = globalUseClasses();
  const pagination = usePagination();
  const navigate = useNavigate();

  const handleAddRaterOpen = (id)=>{
    navigate(`${allConstants.PATH.RATER}${location.search}`);
  };

  const getHeaderComponent = ()=> {
    let title = 'All Raters';

    if(pagination.totalItemsCount) {
      title = `${title} (${pagination.totalItemsCount})`;
    }
    return (
      <ListViewHeader title={title}
        //gridEnum={JobGridEnum}
        pagination={pagination}
        //handleClick={handleClick}
        // menuController={menuController}
        // extendedFilters={shouldHideFilters() ? null : extendedFilters}
        viewController={viewController}
      />
    );
  };

  const headerComponent = <ListPageHeader 
    headComponent = {getHeaderComponent()}
    addHandler={handleAddRaterOpen}
    totalItems={pagination.totalItemsCount}
    showAddButton = {NewPermissionGate({
      user: viewController.user,
      elementPermissions: ratersPageRules[RATER_ELEMENTS.addRaterButton]
    })}
  >
    {pagination.ItemsController}
  </ListPageHeader>;

  return (
    viewController.RenderElement(
      <>
        <NavBar/>
        <ThemeProvider theme={myTheme}>
          <Box className={classes.page}>

            <DisplayListsPageWrapper
              headerComponent={headerComponent}>
              <AllRatersGrid
                viewController={viewController}
                pagination={pagination}
              />
              {pagination.PageController}

            </DisplayListsPageWrapper>
          </Box>
        </ThemeProvider>
      </>
    )
  );
};
export default AllRatersView;
