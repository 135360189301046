// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState} from 'react';
import * as allConstants from '../constants/allConstants';
import ApiUtils from '../utils/apiUtils';



export const AutocompleteFields = {
  [allConstants.COMPANY_FIELDS.LOCATION.api_name]: {
    url: `states/get-key-values`,
    responseKey: 'markets',
  },
  regions: {
    url: `states/get-region-key-values`,
    responseKey: 'regions',
  },
  // [allConstants.COMPANY_FIELDS.LOCATION.api_name]: {
  //   url: `autocomplete/get-markets`,
  //   responseKey: 'markets',
  // },
  cityOptions: {
    url: `autocomplete//get-city-options`,
  },
  jobStatuses: {
    url: `autocomplete/get-all-job-statuses`,
  },
  markets:{
    url: `autocomplete/get-markets`,
    responseKey: 'markets',
  },
  raterDepartments: {
    url: `autocomplete/get-rater-departments`,
    responseKey: 'raterDepartments',
  }
};

export const useAutocompleteOptions = (props) => {
  const field = props.field;
  const [options, setOptions] = useState(null);

  const fetchOptions = async () => {
    try{
      const response = await ApiUtils.makeApiCall('GET', `${AutocompleteFields[field].url}`, undefined);

      if(response?.status === 'success') {
        return AutocompleteFields[field].responseKey ? response.data[AutocompleteFields[field].responseKey] : response.data;
      } else {
        return null;
      }
    } catch (e) {
      console.error(e);
      return null;
    }

  };

  useEffect(async () => {
    const options = await fetchOptions();

    //if (options != undefined && options != null) {
      setOptions(options);
    //}
  }, []);

  return {
    options: options
  };

};
