import {inputTypes} from '../../../../pieces/inputs/formInput';
import * as allConstants from '../../../../constants/allConstants';

const MECH_FIELDS = allConstants.JOB_FIELDS.MECHANICAL;
const SHARED = allConstants.JOB_FIELDS.SHARED;
const EQUIPMENT = allConstants.EQUIPMENT;

const fieldBank = {
  [MECH_FIELDS.TONS.api_name]:{
    field: MECH_FIELDS.TONS,
    inputType: inputTypes.singleSelect,
    options: allConstants.TONS_OPTIONS.BASE,
    requiredFor: [EQUIPMENT.CONDENSER.api_name, EQUIPMENT.HEAT_PUMP_COND.api_name, EQUIPMENT.MINI_SPLIT.api_name,
      EQUIPMENT.MINI_SPLIT_DUCTED.api_name, EQUIPMENT.FURNACE.api_name, EQUIPMENT.GAS_PU.api_name, EQUIPMENT.HEAT_PUMP_PU.api_name]
  },
  [`${MECH_FIELDS.TONS.api_name}-minisplit`]:{
    field: MECH_FIELDS.TONS,
    inputType: inputTypes.singleSelect,
    options: {...allConstants.TONS_OPTIONS.ADDITIONAL, ...allConstants.TONS_OPTIONS.BASE},
    requiredFor: [EQUIPMENT.CONDENSER.api_name, EQUIPMENT.HEAT_PUMP_COND.api_name, EQUIPMENT.MINI_SPLIT.api_name,
      EQUIPMENT.MINI_SPLIT_DUCTED.api_name, EQUIPMENT.FURNACE.api_name, EQUIPMENT.GAS_PU.api_name, EQUIPMENT.HEAT_PUMP_PU.api_name]
  },
  [SHARED.ACTION.api_name]: {
    field: SHARED.ACTION,
    inputType: inputTypes.singleSelect,
    options: allConstants.CO_OR_NEW,
    requiredFor: [EQUIPMENT.CONDENSER.api_name, EQUIPMENT.AIR_HANDLER.api_name, EQUIPMENT.HEAT_PUMP_COND.api_name, EQUIPMENT.MINI_SPLIT.api_name,
      EQUIPMENT.MINI_SPLIT_DUCTED.api_name, EQUIPMENT.FURNACE.api_name, EQUIPMENT.GAS_PU.api_name, EQUIPMENT.HEAT_PUMP_PU.api_name]
  },
  [SHARED.CONVERT_FROM.api_name]: {
    field: SHARED.CONVERT_FROM,
    inputType: undefined,
    requiredFor: []
  },
  [SHARED.CONVERT_TO.api_name]: {
    field: SHARED.CONVERT_TO,
    inputType: undefined,
    requiredFor: []
  },
  [SHARED.LOCATION.api_name]: {
    field: SHARED.LOCATION,
    inputType: inputTypes.singleSelect,
    options: allConstants.EQ_LOCATIONS.MECH_LOCATIONS,
    requiredFor: [EQUIPMENT.CONDENSER.api_name,  EQUIPMENT.AIR_HANDLER.api_name, EQUIPMENT.HEAT_PUMP_COND.api_name, EQUIPMENT.MINI_SPLIT.api_name,
      EQUIPMENT.MINI_SPLIT_DUCTED.api_name, EQUIPMENT.FURNACE.api_name, EQUIPMENT.GAS_PU.api_name, EQUIPMENT.HEAT_PUMP_PU.api_name]
  },
  [MECH_FIELDS.SEER.api_name]: {
    field: MECH_FIELDS.SEER,
    inputType: inputTypes.singleSelect,
    options: allConstants.SEER_OPTIONS,
    requiredFor: [EQUIPMENT.FURNACE.api_name]
  },
  [MECH_FIELDS.MAKE.api_name]: {
    field: MECH_FIELDS.MAKE,
    inputType: undefined,
    requiredFor: []
  },
  [MECH_FIELDS.MODEL.api_name]: {
    field: MECH_FIELDS.MODEL,
    inputType: undefined,
    requiredFor: []
  },
  [MECH_FIELDS.NEED_ELECTRICAL.api_name]: {
    field: MECH_FIELDS.NEED_ELECTRICAL,
    inputType: inputTypes.singleSelect,
    options: allConstants.ANSWER_OPTIONS,
    requiredFor: []
  },
  [MECH_FIELDS.NEED_GAS.api_name]: {
    field: MECH_FIELDS.NEED_GAS,
    inputType: inputTypes.singleSelect,
    options: allConstants.ANSWER_OPTIONS,
    requiredFor: []
  },
  [MECH_FIELDS.SERIAL.api_name]: {
    field: MECH_FIELDS.SERIAL,
    inputType: undefined,
    requiredFor: []
  },
  [MECH_FIELDS.BTUS.api_name]: {
    field: MECH_FIELDS.BTUS,
    inputType: undefined,
    requiredFor: [EQUIPMENT.FURNACE.api_name, EQUIPMENT.AIR_HANDLER.api_name]
  },
  [MECH_FIELDS.AFUE.api_name]: {
    field: MECH_FIELDS.AFUE,
    inputType: undefined,
    requiredFor: [EQUIPMENT.FURNACE.api_name]
  },
  [MECH_FIELDS.HSPF.api_name]: {
    field: MECH_FIELDS.HSPF,
    inputType: undefined,
    requiredFor: [EQUIPMENT.AIR_HANDLER.api_name]
  },
  [MECH_FIELDS.SUPPLY.api_name]: {
    field: MECH_FIELDS.SUPPLY,
    inputType: undefined,
    requiredFor: [EQUIPMENT.R6.api_name, EQUIPMENT.R8.api_name, EQUIPMENT.R10.api_name, EQUIPMENT.MINI_SPLIT_DUCTED.api_name]
  },
  [MECH_FIELDS.RETURN.api_name]: {
    field: MECH_FIELDS.RETURN,
    inputType: undefined,
    requiredFor: [EQUIPMENT.R6.api_name, EQUIPMENT.R8.api_name, EQUIPMENT.R10.api_name, EQUIPMENT.MINI_SPLIT_DUCTED.api_name]
  },
  [MECH_FIELDS.ENTIRELY_NEW_DUCT.api_name]: {
    field: MECH_FIELDS.ENTIRELY_NEW_DUCT,
    inputType: inputTypes.singleSelect,
    options: allConstants.ANSWER_OPTIONS,
    requiredFor: [EQUIPMENT.R6.api_name, EQUIPMENT.R8.api_name, EQUIPMENT.R10.api_name]
  },
  [MECH_FIELDS.AH_QTY.api_name]: {
    field: MECH_FIELDS.AH_QTY,
    inputType: undefined,
    requiredFor: [EQUIPMENT.MINI_SPLIT.api_name, EQUIPMENT.MINI_SPLIT_DUCTED.api_name]
  },
};


export {fieldBank};
