import React, {useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import * as allConstants from '../../../../constants/allConstants';
import Utils from '../../../../utils/utils';
import actions from '../../../../redux/actions';
import mechanicalUtils from '../mechanical/mechUtils';
import {useForm} from 'react-hook-form';
import {Box, Grid, Typography} from '@mui/material';
import TabsBlock from '../../../../pieces/tabsBlock';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import clsx from 'clsx';
import {useCreateJobStyles} from '../createJobStyles';
import EquipmentPanelWrapper from '../equipmentPanelWrapper';
import {inputTypes} from '../../../../pieces/inputs/formInput';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme=> {
  return {
    ins_field:{
      marginRight: '50px',
    },
  };
});


const WaterHeaterEquipment = (props)=> {
  const dispatch = useDispatch();
  const classes = {...useCreateJobStyles(), ...useStyles()};
  const subforms = useSelector(state => state.subforms);
  const PL_FIELDS = allConstants.JOB_FIELDS.PLUMBING;
  const JOB_TYPES = allConstants.JOB_TYPES;
  const SHARED_FIELDS = allConstants.JOB_FIELDS.SHARED;

  useEffect(()=> {
    saveSubform(allConstants.JOB_TYPES.PLUMBING.api_name, formMethods);
  }, []);



  useEffect(()=> {
    return ()=> {
      const currValues = formMethods.getValues();
      dispatch(actions.addSubform({jobType: allConstants.JOB_TYPES.PLUMBING.api_name,
        subformName: allConstants.JOB_FIELDS.WATER_HEATER_SYSTEM.api_name, subform: currValues}));
    };

  },[]);

  const canModifyEquipment = useRef(Utils.canModifyEquipment(props.draftData));

  const getFields = (fieldArr, extraClasses='', actionOption = '')=> {

    return (
      fieldArr.map((f)=> {
        const additionalData = {};
        const uniqueProps = {};
        let options;
        const smallInputs = [
          SHARED_FIELDS.QUANTITY, PL_FIELDS.GALLONS, PL_FIELDS.ENERGY_FACTOR, PL_FIELDS.INS_R_VALUE, PL_FIELDS.BTU_INPUT
        ];
        if(f.api_name === SHARED_FIELDS.ACTION.api_name) {
          additionalData.inputType = inputTypes.singleSelect;

          if (actionOption === "Traditional") {
            options = allConstants.ACTION_TRADITIONAL;
          }
          else if (actionOption === "Tankless") {
            options = allConstants.ACTION_TANKLESS
          }
          else {
            options = allConstants.CO_OR_NEW;
          }


        } else if(f.api_name === SHARED_FIELDS.LOCATION.api_name) {
          additionalData.inputType = inputTypes.singleSelect;
          options = allConstants.EQ_LOCATIONS.WATER_HEATER;
        } else if(f.api_name === PL_FIELDS.FUEL_TYPE.api_name) {
          additionalData.inputType = inputTypes.singleSelect;
          options = allConstants.FUEL_TYPES;
        } else if(f.api_name === SHARED_FIELDS.GAS_TO_METER.api_name || f.api_name === SHARED_FIELDS.IS_ELECTRICAL.api_name){
          additionalData.inputType = inputTypes.checkbox;
          uniqueProps.renderLabel = false;
        }
        return {
          ...additionalData, ...{
            wrapperClass: smallInputs.includes(f) ? clsx(classes.small_input_box,
              f===PL_FIELDS.INS_R_VALUE ? classes.ins_field : '') : extraClasses,
            field: f,
            uniqueProps: uniqueProps,
            options: options
          }
        };
      })
    );
  };

  const mapPanelsToEq = {
    [PL_FIELDS.WH_TRADITIONAL.api_name]: [allConstants.EQUIPMENT.WATER_HEATER_TRADITIONAL.api_name],
    [PL_FIELDS.WH_TANKLESS.api_name]:[allConstants.EQUIPMENT.WATER_HEATER_TANKLESS.api_name],
    [PL_FIELDS.EXP_TANKS.api_name]: [allConstants.EQUIPMENT.EXPANSION_TANKS.api_name],
  };
  const fields = [
    PL_FIELDS.WH_TRADITIONAL, PL_FIELDS.WH_TANKLESS, PL_FIELDS.EXP_TANKS
  ];

  const whTraditionalFields = [SHARED_FIELDS.QUANTITY, PL_FIELDS.GALLONS, SHARED_FIELDS.ACTION,
    SHARED_FIELDS.LOCATION, PL_FIELDS.MAKE, PL_FIELDS.MODEL, PL_FIELDS.FUEL_TYPE, PL_FIELDS.ENERGY_FACTOR, PL_FIELDS.INS_R_VALUE,
    PL_FIELDS.BTU_INPUT];
  const whTanklessFields = [SHARED_FIELDS.ACTION, SHARED_FIELDS.LOCATION, PL_FIELDS.MAKE, PL_FIELDS.MODEL, SHARED_FIELDS.GAS_TO_METER, SHARED_FIELDS.IS_ELECTRICAL];
  const expTanksFields = [SHARED_FIELDS.QUANTITY,PL_FIELDS.GALLONS];

  const saveSubform = useMemo(()=> props.setEquipmentForm, []);

  const equipmentMap = {
    [allConstants.EQUIPMENT.WATER_HEATER_TRADITIONAL.api_name]: {
      name: allConstants.EQUIPMENT.WATER_HEATER_TRADITIONAL.api_name,
      fields: getFields(whTraditionalFields, '', 'Traditional')
    },
    [allConstants.EQUIPMENT.WATER_HEATER_TANKLESS.api_name]: {
      name: allConstants.EQUIPMENT.WATER_HEATER_TANKLESS.api_name,
      fields: getFields(whTanklessFields,  '', 'Tankless')
    },
    [allConstants.EQUIPMENT.EXPANSION_TANKS.api_name]: {
      name: allConstants.EQUIPMENT.EXPANSION_TANKS.api_name,
      fields: getFields(expTanksFields)
    }
  };
  const def = useMemo(()=>  mechanicalUtils.getSystemFormOptions(equipmentMap));

  const formOptions = {...def};

  const {updatedDefault} = mechanicalUtils.updateSystemDefaultValues(allConstants.JOB_FIELDS.WATER_HEATER_SYSTEM.api_name, allConstants.SYSTEM_CONTENT[allConstants.JOB_FIELDS.WATER_HEATER_SYSTEM.api_name], subforms,
    formOptions.defaultValues, JOB_TYPES.PLUMBING.api_name);

  formOptions.defaultValues = {...updatedDefault};

  const isEquipmentTabDisabled = (eqName)=> {
    if(canModifyEquipment.current) return false;
    try{
      const equipment = {...updatedDefault[eqName][0].fields};
      if(equipment.length > 1) return false;
      const eqValues = Object.values(equipment[0]).filter((v)=> v);
      return eqValues.length === 0;
    } catch(e){}
    return false;
  };


  const formMethods = useForm(formOptions);

  window.wh_form = formMethods;
  const inputDefaultProps = mechanicalUtils.getSystemInputDefaultProps(formMethods);

  const shouldShowMessage = (panel)=> {
    return panel === PL_FIELDS.WH_TANKLESS.api_name;
  };

  const content = fields.map((f)=> {
    return {
      name: f.api_name,
      label: f.label,
      isDisabled: isEquipmentTabDisabled(f.api_name),
      panel: ()=>  <EquipmentPanelWrapper {...props}
        formMethods={formMethods}
        inputs={Object.values(equipmentMap).map((piece)=> {
          return Object.values(piece.fields);
        })}
        eqName={mapPanelsToEq[f.api_name]}
        addFields={def.defaultValues?.[mapPanelsToEq[f.api_name]][0]?.fields[0]}
        inputDefaultProps={inputDefaultProps}
        equipmentMap={equipmentMap}
        canModifyEquipment={props.canModifyEquipment}
      >
        {shouldShowMessage(f.api_name) && <Box mb={2} width={'100%'}>
          <Typography variant={'body1'} color={STYLE_CONSTANTS.COLORS.globalRed}>
            ***Isometric drawing required for all TANKLESS Water Heaters***
          </Typography>
        </Box>}
      </EquipmentPanelWrapper>
    };
  });

  return (
    <Grid item xs={12} md={10} sx={{maxWidth: '840px'}}>
      {!canModifyEquipment.current && <Box mt={2}>
        <Typography sx={{color: STYLE_CONSTANTS.COLORS.globalRed}}>You can&apos;t change selected equipment for job submitted from Service Titan</Typography>
      </Box>}
      <TabsBlock content={content} {...inputDefaultProps}/>
    </Grid>
  );
};

WaterHeaterEquipment.propTypes = {
  draftData: PropTypes.instanceOf(Object),
  setEquipmentForm: PropTypes.func.isRequired,
  canModifyEquipment: PropTypes.bool
};

WaterHeaterEquipment.defaultProps = {
  canModifyEquipment: false,
};

export default WaterHeaterEquipment;
