import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {useForm, FormProvider} from 'react-hook-form';
import base_url from '../../../baseUrls';
import axios from 'axios';
import ApiUtils from '../../../utils/apiUtils';
import * as allConstants from '../../../constants/allConstants';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {ERROR_MSG} from '../../../constants/allConstants';
import validator from '../../../utils/validators/validator';
import {Box, CardContent, Grid, Card} from '@mui/material';
import CustomFormBlock from '../../../pieces/inputs/formBlock';
import {inputTypes} from '../../../pieces/inputs/formInput';
import Utils from '../../../utils/utils';
import FormSubmitButtons from '../../../pieces/formSubmitButtons';
import {globalUseClasses} from '../../../styles/globalClasses';
import {unwrapRequestResult} from '../../../utils/unwrapRequestResult';
import actions from '../../../redux/actions';
import {errorHandler} from '../../../utils/errorHandler';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {urlSearchParams} from '../../../baseUrls';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import { DataManager, Query, UrlAdaptor } from '@syncfusion/ej2-data';
import { MultiSelectComponent, DropDownListComponent, AutoCompleteComponent} from '@syncfusion/ej2-react-dropdowns';
import './userInfo.css';
import { ContentPasteSearchOutlined } from '@mui/icons-material';

class CustomUrlAdaptor extends UrlAdaptor {

  // Override the processResponse method
  processResponse(data, success) {

      console.log("return data => ", data);
      if (success) {
         return data.data;
      } else {
         // Handle errors or failed requests
         console.error('Request failed:', data);
         return [];
      }
  }
}

export const UserInfoPanel = (props) => {

  const companyFields = { text: 'companyName', value: 'companyId' };
  const [init] = useState(true);
  const [availableCompanies, setAvailableCompanies] = useState([]);
  const [availableCompaniesWithCurrent, setAvailableCompaniesWithCurrent] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [curAutoCompany, setCurAutoCompany] = useState();
  const [curCompanyId, setCurCompanyId] = useState();

  const [companyDataMgr, setCompanyDataMgr] = useState([]);
  const {USER_FIELDS} = allConstants;
  const [userData, setUserData] = useState(props?.userData || {});
  const classes = {...globalUseClasses()};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state=> state.user);
  const userRole = user[allConstants.USER_FIELDS.ROLE.api_name];
  const isCompanyAdmin = userRole === allConstants.CONTRACTOR_DISPLAY_ROLES.COMPANY_ADMIN.value;

  let userCompanyRef = useRef(null);
  let authToken = useRef(null);
  const queryCompany = new Query().take(30).skip(0);
  
  const getDefaultValue = (field)=> {
    let defValue = '';
    if (field.api_name == USER_FIELDS.EMAIL.api_name) {
      defValue  = Utils.getUrlParameter('email') ?? '';
    }
    if (field.api_name == USER_FIELDS.FIRST_NAME.api_name) {
      defValue  = Utils.getUrlParameter('firstName') ?? '';
    }
    if (field.api_name == USER_FIELDS.LAST_NAME.api_name) {
      defValue  = Utils.getUrlParameter('lastName') ?? '';
    }
    return userData?.[field.api_name]||defValue;
  };

  const getFormDefaultValues = ()=> {
    return {
      [USER_FIELDS.FIRST_NAME.api_name]: getDefaultValue(USER_FIELDS.FIRST_NAME),
      [USER_FIELDS.LAST_NAME.api_name]: getDefaultValue(USER_FIELDS.LAST_NAME),
      [USER_FIELDS.EMAIL.api_name]: getDefaultValue(USER_FIELDS.EMAIL),
      [USER_FIELDS.PHONE_NUMBER.api_name]: getDefaultValue(USER_FIELDS.PHONE_NUMBER),
      [USER_FIELDS.TITLE.api_name]: getDefaultValue(USER_FIELDS.TITLE),
      [USER_FIELDS.ROLE.api_name]: getDefaultValue(USER_FIELDS.ROLE),
      [USER_FIELDS.COMPANY_ID.api_name]: getDefaultValue(USER_FIELDS.COMPANY_ID),
    };
  };

  Yup.addMethod(Yup.string, 'checkIfEmailExists', validator.checkIfEmailExists);
  Yup.addMethod(Yup.string, 'validatePhoneNumber', function (errorMessage) {
    return this.test('phone_number', errorMessage, function (value) {
      const {path, createError} = this;
      return (
        validator.isValidPhoneNumber(value) ||
        createError({path, message: errorMessage})
      );
    });
  });
  Yup.addMethod(Yup.string, 'validateEmail', function (errorMessage) {
    return this.test('email', errorMessage, function (value) {
      const {path, createError} = this;
      return (
        validator.isValidEmail(value) ||
        createError({path, message: errorMessage})
      );
    });
  });

  const formOptions = {
    mode: 'onBlur',
    criteriaMode:'all',
    reValidateMode: 'onChange',
    defaultValues: getFormDefaultValues(),
    resolver: yupResolver(Yup.object().shape({
      [USER_FIELDS.FIRST_NAME.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD),
      [USER_FIELDS.LAST_NAME.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD),
      [USER_FIELDS.EMAIL.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD)
        .validateEmail(ERROR_MSG.EMAIL_INVALID)
        .test(
          {
            test: value => {
              return Yup.string().checkIfEmailExists(value, userData[USER_FIELDS.EMAIL.api_name]);
            },
            message: allConstants.ERROR_MSG.USER_ALREADY_EXISTS
          }
        ),
      [USER_FIELDS.PHONE_NUMBER.api_name]: Yup.string().validatePhoneNumber(ERROR_MSG.INVALID_PHONE).nullable(),
    })),
  };

  const formMethods = useForm(formOptions);

  useEffect( async () => {

    authToken.current = await ApiUtils.getAccessToken();
    
    const elemUserCompany = userCompanyRef.current.element;
    elemUserCompany.addEventListener('mousedown', handleMouseDownUserCompany);

    return () => {
      elemUserCompany.removeEventListener('mousedown', handleMouseDownUserCompany);       
    };

  }, [init])

  const handleMouseDownUserCompany = () => {
    if (userCompanyRef.current) {
      userCompanyRef.current.showPopup();
    }
};

  useEffect(()=> {
    formMethods.reset(getFormDefaultValues());
  
    // console.log('userData in use effect');
    // console.log(userData);
    // console.log(userData.companyName);

    
    if (!userData || !userData?.companyName) {
      return;
    }

    if (!isCompanyAdmin) {
      //console.log('about to call autocomplete');

      setCurAutoCompany(userData.companyName);

      const urlCompany = `${base_url.api}companies/autocomplete`;
      const compDataMgr = new DataManager({
        url: urlCompany,
        adaptor: new CustomUrlAdaptor(),
        crossDomain: true,
        offline: false,
        headers: [ {'Authorization': authToken.current}],
        onFailure: (e) => {
            console.error('API call failed', e);
        }
      });
      setCompanyDataMgr(compDataMgr);
  
      //console.log('after autocomplete call');  
    }
    else {

      //console.log('use dropdownlist');
      //console.log(userData.companyId);

      setCurCompanyId(userData.companyId);


    }

    //if (userData.isInvite) {
      //console.log('new user');
     // {...userData?.[USER_FIELDS.EMAIL.api_name]} = 'thanhnpham@hotmail.com';
    //}
  }, [userData]);

  useEffect( async ()=> {

    //console.log('inside useEffect prop.userData');
    //console.log(props.userData);

    if (!props.userData || !props.userData?.companyId) {
      return;
    }
    setCurAutoCompany(props.userData.companyName);

    const url = `${base_url.api}companies/get-user-available-companies`;
    const authToken = await ApiUtils.getAccessToken();

    const response = await axios.post(url, { companyId: props.userData.companyId}, {
      headers: {
        Authorization: authToken,
      }});

    const selCompanies = props.userData.userCompanies.map( (x) => x.companyId );

    //console.log('available companies');
    //console.log(response.data.data);
  
    setAvailableCompanies(response.data.data);
    setAvailableCompaniesWithCurrent([{companyId: props.userData.companyId, companyName: props.userData.companyName},
                               ...response.data.data]);
  
    //console.log('new company list');
    //console.log(availableCompaniesWithCurrent);

 
    setSelectedCompanies(selCompanies);
    setUserData(props.userData);
    

  },[props.userData]);


  if(ApiUtils.isDevelopment()) {
    window.userForm = formMethods;
  }

  const submitInvite = async (data) => {
    const companyId = props.currentCompany[allConstants.COMPANY_FIELDS.ID.api_name];
    if(!companyId) {
      Utils.errorToastMessage('Company is not selected');
      return;
    }
    props.setSubmitting(true);
    const newData = {...data, ...{
      [allConstants.USER_FIELDS.COMPANY_ID.api_name]: companyId},
    [allConstants.USER_FIELDS.ROLE.api_name]: allConstants.ROLES.CONTRACTOR,
    };
    const inviteResult = await dispatch(actions.inviteUser(newData));
    if(inviteResult) {
      unwrapRequestResult({
        showSuccess: true,
        result: inviteResult,
        successMessage: 'New contractor has been invited.',
        id: 'invite_contractor',
        errorMessage: errorHandler.getErrorMessage(inviteResult?.payload?.errorCode, 'Failed to invite new user')
      });

      const user = inviteResult?.payload?.user;
      if(user) {
        navigate(`${allConstants.PATH.USER}${location.search}&${urlSearchParams.userId}=${user.id}`);
      }
      props.setViewMode(true);
      props.setSubmitting(false);
    }
  };

  const onSubmit = async (data)=> {
    if(props.isInvite) {
      submitInvite(data);
    } 
    else {
      props.setSubmitting(true);

      //console.log('about to update user');
      //console.log(data);


      try{
        let errorMessage = 'Failed to edit user';
        let successMessage = 'User ' + data[USER_FIELDS.FIRST_NAME.api_name] + ' ' + data[USER_FIELDS.LAST_NAME.api_name] + ' successfully edited.';
        // const editResponse = await ApiUtils.makeApiCall('POST', `users/update-user-data/${userData.id}`, JSON.stringify(data));

        //console.log('data to update user');
        //console.log(data);

        const editResponse = await ApiUtils.makeApiCall('POST', `users/update-contractor-data/${userData.id}`, JSON.stringify(data));

        if(editResponse.status === 'error') {
          toast.error(editResponse?.message || errorMessage, {
            toastId: 'editContractorError',
          });
        } else {
          toast.success(successMessage, {
            toastId: 'editContractorSuccess',
          });
        }
        
        const user = editResponse?.payload?.user;

        //console.log('updated user ');
        //console.log(user);

        if(user) {
          props.setUserData(user);
        }

      } catch (e) {
        console.error(e);
        Utils.errorToastMessage(`Error editing user. ${e?.message}`);
      }
      props.setViewMode(true);
      props.setSubmitting(false);
    }


  };

  const onCancel = ()=> {
    props.setViewMode(true);
  };

  const inputDefaultProps = {
    viewMode: props.viewMode,
    renderLabel: true,
    isEditable: true,
  };

  const handleChangeUserCompany = (event) => {

    //console.log('inside user company');
    //console.log("Selected Values: ", event.value); // Logs the selected values
    //console.log(availableCompanies);

    const userComps = availableCompanies.filter ( item => event.value.includes(item.companyId));

    //console.log('company list');
    //console.log(userComps);

    formMethods.setValue(USER_FIELDS.USER_COMPANIES.api_name, userComps); // Updates the selected values in state

   
  };

  
  const onChangeCompany = (args) => {

    //console.log('on change company');
    //console.log(args.itemData);

    if (args.itemData) {       // setSelectedCompanyId(args.itemData.companyId);   
      formMethods.setValue(`${[USER_FIELDS.COMPANY_ID.api_name]}`, args.itemData.companyId);     
      setCurAutoCompany(args.itemData.companyName);
    }
    else {
      formMethods.setValue(`${[USER_FIELDS.COMPANY_ID.api_name]}`, '');     
    }
  };

  const onChangeDropdownCompany = (args) => {
   
    //console.log('on change drop down company');
    //console.log(args.itemData);

    if (args.itemData) {       // setSelectedCompanyId(args.itemData.companyId);   
      formMethods.setValue(`${[USER_FIELDS.COMPANY_ID.api_name]}`, args.itemData.companyId);     
      setCurCompanyId(args.itemData.companyId);
    }
    else {
      formMethods.setValue(`${[USER_FIELDS.COMPANY_ID.api_name]}`, '');  
      setCurCompanyId(null);  
    }
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} method={'POST'}>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <Card className={classes.card_block} sx={{height: '100%'}} >
                <CardContent className={classes.half_width} >
                  <Box className={classes.two_col_container}>
                    <CustomFormBlock
                      {...inputDefaultProps}
                      field={USER_FIELDS.FIRST_NAME}
                      fullWidth
                    />
                    <CustomFormBlock
                      {...inputDefaultProps}
                      field={USER_FIELDS.LAST_NAME}
                      fullWidth
                    />
                  </Box>
                  <CustomFormBlock
                    {...inputDefaultProps}
                    field={{...USER_FIELDS.EMAIL, ...{required: true}}}
                    isEditable={props.isInvite}
                    fullWidth
                    preventCapitalize={true}
                  />
                  {!props.isInvite  && <>
                  <CustomFormBlock
                    {...inputDefaultProps}
                    inputType={inputTypes.singleSelect}
                    labelSecondary={true}
                    field={allConstants.USER_FIELDS.ROLE}
                    options={Utils.getOptions(allConstants.CONTRACTOR_DISPLAY_ROLES)}
                    defaultValue={getDefaultValue(allConstants.USER_FIELDS.ROLE)}
                    fullWidth={true}
                  />
                  <CustomFormBlock
                    {...inputDefaultProps}
                    field={USER_FIELDS.COMPANY_ID}
                    hide={true}
                    defaultValue={getDefaultValue(allConstants.USER_FIELDS.COMPANY_ID)}
                    fullWidth
                    preventCapitalize={true}
                  />
                  <div className="box-container">
                    <label className="box-label">{USER_FIELDS.COMPANY_ID.label}</label>
                    <Box sx={{
                        border: '2px solid lightgrey',  
                        padding: '4px',
                        borderRadius: '4px',
                        width: '100%',
                        position: 'relavtive',
                        marginBottom: '5px',
                      }} >
                      {!isCompanyAdmin && companyDataMgr && 
                        <AutoCompleteComponent id={USER_FIELDS.COMPANY_ID.label}  sortOrder="Ascending"
                              dataSource={companyDataMgr} query={queryCompany} fields={{ value: 'companyName', text: 'companyName'}} 
                              enabled={!props.viewMode}                                              
                              value={curAutoCompany} minLength={3} filterType='Contains' change={onChangeCompany}
                              style={{ borderRadius: '8px',  width: '450px', marginRight: '15px', paddingLeft: '4px' }}/>    
                      }   
                      {isCompanyAdmin &&
                        <DropDownListComponent id="ddlAvailableCompanies" dataSource={availableCompaniesWithCurrent}  placeholder="" 
                          value={curCompanyId}  fields={{ value: 'companyId', text: 'companyName'}} 
                          enabled={!props.viewMode} 
                          popupHeight="450px" style={{ borderRadius: '8px', backgroundColor: 'white', paddingLeft: '2px'}}
                          change={onChangeDropdownCompany}  /> 
                      }           
                    </Box>
                  </div>
                  </>}
                  <Box className={classes.two_col_container}>
                    <CustomFormBlock
                      {...inputDefaultProps}
                      inputType={inputTypes.phone}
                      field={USER_FIELDS.PHONE_NUMBER}
                      defaultValue={Utils.reformatPhoneNumber(getDefaultValue(USER_FIELDS.PHONE_NUMBER))}
                      fullWidth
                    />
                    <CustomFormBlock
                      {...inputDefaultProps}
                      field={USER_FIELDS.TITLE}
                      fullWidth
                    />
                  </Box>
                  <div className="box-container">
                    <label className="box-label">{USER_FIELDS.USER_COMPANIES.label}</label>
                    <Box sx={{
                        border: '2px solid lightgrey',  
                        padding: '4px',
                        paddingTop: '12px',
                        borderRadius: '4px',
                        width: '100%',
                        position: 'relavtive',
                      }} > 
                      <MultiSelectComponent id="userCompany" ref={userCompanyRef} dataSource={availableCompanies} 
                          mode="Box" placeholder="Select one or more companies for the user" 
                          fields={companyFields} value={selectedCompanies}
                          style={{width: '100%', height: '500px', topMargin: '4px'}} 
                          enabled={!props.viewMode}  change={handleChangeUserCompany}              
                        />
                    </Box>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            {!props.viewMode && <Grid xs={12}>
              <Box display={'flex'} justifyContent={'center'}>
                <FormSubmitButtons cancelAction={onCancel} submitText={props.isInvite ? 'Invite' : 'Update'} cancelText={'Cancel'}/>
              </Box>
            </Grid>}
          </Grid>

        </form>
      </FormProvider>
    </>
  );
};

UserInfoPanel.propTypes = {
  userData: PropTypes.instanceOf(Object),
  viewMode: PropTypes.bool,
  setViewMode: PropTypes.func,
  setSubmitting: PropTypes.func,
  isInvite: PropTypes.bool,
  currentCompany: PropTypes.instanceOf(Object),
  setUserData: PropTypes.func,

};

UserInfoPanel.defaultProps = {
  userData: {},
  viewMode: true,
  setViewMode: ()=> {},
  setSubmitting: ()=> {},
  isInvite: false,
  currentCompany: {},
  setUserData: ()=> {},

};
