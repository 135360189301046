import React, {useState} from 'react';
import PropTypes from 'prop-types';
import base_url from '../../baseUrls';
import * as allConstants from '../../constants/allConstants';
import MyPaginatedGrid from '../../pieces/grids/paginatedGridWrapper';
import ColumnDefinition from '../../pieces/grids/columnGenerator';
import {NewPermissionGate} from '../../permissions/permissionGate';
//import {MaximizableCellRenderer} from '../../pieces/grids/MaximizableCellRenderer';
import ApiUtils from '../../utils/apiUtils';
import {useAutocompleteOptions} from '../../hooks/useAutompleteOptions';
import Utils from '../../utils/utils';
import {STATE_ELEMENTS, stateRules} from '../../permissions/stateAccessControl';

const AllStatesGrid = (props) => {
  const viewController = props.viewController;
  // const [components] = useState({
  //   'MaximizableCellRenderer': MaximizableCellRenderer,
  // });

  // const handleDisplayStateDetails = (params) => {
  //   props.viewStateHandler(params?.node?.data.id);
  // };


  let columnDefs = [
    new ColumnDefinition({field: allConstants.STATE_FIELDS.ID, hide: true}),

    new ColumnDefinition({
      field: allConstants.STATE_FIELDS.NAME,
      lockPinned: true,
      pinned: 'left',
      checkboxSelection: true,
      width: 350,
      wrapText: true
    }),
    new ColumnDefinition({
      field: allConstants.STATE_FIELDS.REGIONS,
      //ortable: true,
      headerName: 'Regions',
      //wrapText: true,
      // cellStyle: {
      //   lineHeight: '14px',
      //   display: 'flex',
      //   flexDirection: 'column',
      //   justifyContent: 'center',
      //   height: '100%',
      // },
      //filter: 'agSetColumnFilter',
      //filterValues: tagNameList,
      valueGetter: (({data}) => {
        const regions = data?.[allConstants.STATE_FIELDS.REGIONS.api_name];

        const regionNames = regions.map( x => x.name );
        return regionNames.join(', ');
      }),
      //cellRenderer: 'LineBreakCellRenderer',
    }),
  ]

  const editStateHandler = (stateData)=> {
    props.editStateHandler(stateData);
  };

  const deleteStateHandler = (stateData)=> {
    props.deleteStateHandler(stateData);
  };

  const getContextMenuItems = (params)=> {
    if(!params.node) return [];

    const standardActions = [
      {
        name:  'Export Selected (.xlsx)',
        action: () => params.api.exportDataAsExcel(
          {onlySelected: true}
        )
      },
      'copy',
    ];

    const actions = {
      edit: {
        name: 'Edit',
        disabled: viewController.selectedMultipleRows(),
        action: () => {
          editStateHandler(params?.node?.data);
        },
      },
      delete: {
        name: 'Delete',
        cssClasses: ['redFont', 'bold'],
        disabled: viewController.selectedMultipleRows(),
        action: () => {
          deleteStateHandler(params?.node?.data);
        },
      },
    };
    // const actions = {
    //   edit: {
    //     name: 'View/Edit',
    //     disabled: viewController.selectedMultipleRows(),
    //     action: () => {
    //       props.viewStateHandler(params?.node?.data?.id);
    //     },
    //   },
    //   delete: {
    //     name: 'Delete',
    //     cssClasses: ['redFont', 'bold'],
    //     disabled: viewController.selectedMultipleRows(),
    //     action: () => {
    //       deleteStateHandler(params?.node?.data);
    //     },
    //   },
    // };

    let allowedActions = [];
    if(NewPermissionGate({
      user: viewController.user,
      elementPermissions: stateRules[STATE_ELEMENTS.editState],
    })) {
      allowedActions.push(actions.edit);
    }

    allowedActions = [...allowedActions, ...standardActions];
    return allowedActions;

  };

  const ls_name = 'states_grid';

  return (
    <MyPaginatedGrid columnDefs={columnDefs}
      masterDetail={true}
      //components={components}
      contextMenu={getContextMenuItems}
      ls_name={ls_name}
      registerGrid={viewController.setGridToParent}
      registerTempGrid={viewController.setTempGridToParent} 
      fetchParams={{url: `${base_url.api}states/`, respKey:'states'}}
      companyId={viewController.companyId}
      onFetchFail={viewController.onFetchFail}
      pagination={props.pagination}
    />
  );
};

export default AllStatesGrid;


