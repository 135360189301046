import React, {useRebate, useCallback, useState} from 'react';
import {ThemeProvider, Box} from '@mui/material';
import NavBar from '../../pieces/navbar/navbar';
import {NewPermissionGate} from '../../permissions/permissionGate';
import myTheme from '../../styles/myStyles';
import DisplayListsPageWrapper from '../wrappers/displayListsPageWrapper';
import ListPageHeader from '../../pieces/listPageHeader';
import {ListViewHeader} from '../jobs/components/listViewHeader';
import usePagination from '../../hooks/usePagination';
import {useGridViewState} from '../../hooks/useGridViewState';
import {globalUseClasses} from '../../styles/globalClasses';
import {REBATE_ELEMENTS, rebateRules} from '../../permissions/rebateAccessControl';
import AllRebatesGrid from './allRebatesGrid';
import RebateForm from './rebateForm';
import GenericModal from '../../pieces/modals/tagModal';
import ConfirmDelete from '../../pieces/modals/confirmDelete';
import base_url from '../../baseUrls';

const AllRebatesView = () => {
  const viewController = useGridViewState();
  const classes = globalUseClasses();
  const pagination = usePagination();

  const [selectedRebate, setSelectedRebate] = useState(undefined);
  const [showAddRebatePopup, setShowAddRebatePopup] = useState(false);
  const [showEditRebatePopup, setShowEditRebatePopup] = useState(false);
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleAddRebateOpen = useCallback(() => setShowAddRebatePopup(true), []);  
  const handleAddRebateClose = () => setShowAddRebatePopup(false);
  const handleEditRebateClose = () => setShowEditRebatePopup(false);
  const handleConfirmDeletePopupClose = () => setShowConfirmDeletePopup(false);


  const editRebateHandler = (data)=> {

    //console.log('inside editRebatehandler');
    //console.log(data);

    setSelectedRebate(data);
    setShowEditRebatePopup(true);
  };

  const editRebateCancel = ()=> {
    setSelectedRebate(undefined);
    setShowEditRebatePopup(false);
  };

  const handleSuccess = ()=> {
    if(showAddRebatePopup || showEditRebatePopup || showConfirmDeletePopup) {
      window.location.reload();
    }
  };

  const deleteRebateHandler = (data)=> {    
    setSelectedRebate(data);
    setShowConfirmDeletePopup(true);
  };

  const getDeleteURL = ()=> {
    if(!selectedRebate) return null;
    return `${base_url.api}rebates/delete`;
  };

  const deleteCancelHandler = ()=> {
    setSelectedRebate(undefined);
    setShowConfirmDeletePopup(false);
  };

  const getHeaderComponent = ()=> {
    let title = 'All Rebates';

    if(pagination.totalItemsCount) {
      title = `${title} (${pagination.totalItemsCount})`;
    }
    return (
      <ListViewHeader title={title}
        //gridEnum={JobGridEnum}
        pagination={pagination}
        //handleClick={handleClick}
        // menuController={menuController}
        // extendedFilters={shouldHideFilters() ? null : extendedFilters}
        viewController={viewController}
      />
    );
  };

  const headerComponent = <ListPageHeader
    headComponent = {getHeaderComponent()}
    addHandler={handleAddRebateOpen}
    totalItems={pagination.totalItemsCount}
    showAddButton = {NewPermissionGate({
      user: viewController.user,
      elementPermissions: rebateRules[REBATE_ELEMENTS.addRebate],
    })}
  >
    {pagination.ItemsController}
  </ListPageHeader>;

  return (
    viewController.RenderElement(
      <>
        <NavBar/>
        <ThemeProvider theme={myTheme}>
          <Box className={classes.page}>
            <GenericModal open={showEditRebatePopup}
              handleClose={handleEditRebateClose}
              header={'Edit Rebate'}
              preventScroll={false}
            >
              <RebateForm closeModal={handleEditRebateClose}
                editMode={true}
                successAction={handleSuccess}
                rebateData={selectedRebate}/>
            </GenericModal>

            <GenericModal open={showAddRebatePopup}
              handleClose={handleAddRebateClose}
              header={'Create Rebate'}
              preventScroll={false}
            >
              <RebateForm closeModal={handleAddRebateClose}
                successAction={handleSuccess}
                editMode={false}/>
            </GenericModal >

            <ConfirmDelete deleteUrl={getDeleteURL()}
              open={showConfirmDeletePopup}
              submitDeleteAction={handleConfirmDeletePopupClose}
              handleCancel={deleteCancelHandler}
              successAction={handleSuccess}
              idToDelete={selectedRebate?.id}
              entityName={'rebate'}
              recordName={selectedRebate ? selectedRebate.name : ''}
            />

            <DisplayListsPageWrapper
              headerComponent={headerComponent}>
              <AllRebatesGrid 
                editRebateHandler={editRebateHandler}
                editRebateCancel={editRebateCancel}
                viewController={viewController}
                deleteRebateHandler={deleteRebateHandler}
                deleteRebateCancel={deleteCancelHandler}

                // setBlocked={viewController.setBlocked}
                pagination={pagination}
              />
              {pagination.PageController}

            </DisplayListsPageWrapper>
          </Box>
        </ThemeProvider>
      </>
    )
  );
};

export default AllRebatesView;
