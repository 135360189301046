import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../constants/allConstants';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import CustomFormBlock from '../../pieces/inputs/formBlock';
import {Box, Grid} from '@mui/material';
import FormSubmitButtons from '../../pieces/formSubmitButtons';
import validator from '../../utils/validators/validator';
import {inputTypes} from '../../pieces/inputs/formInput';
import ApiUtils from '../../utils/apiUtils';
import base_url from '../../baseUrls';
import Utils from '../../utils/utils';
import clsx from 'clsx';
import {globalUseClasses} from '../../styles/globalClasses';
import {toast} from 'react-toastify';
import axios from 'axios';

const ProductForm = (props)=> {
  const {PRODUCT_FIELDS} = allConstants;
  const classes = {...globalUseClasses()};
  const [canSubmit, setCanSubmit] = useState(true);

  Yup.addMethod(Yup.string, 'checkIfProductExists', async function ( value, key, id ) {
    let exists = false;
    if(!value) return false;
    try{
      const authToken = await ApiUtils.getAccessToken();
      let url = `${base_url.api}products/product-is-unique?${key}=${value}`;
      if(id) {
        url += `&id=${id}`;
      }

      console.log('inside product is unit url => ', url);

      const response = await axios.get(url, {
        headers: {Authorization: authToken}});
      if(response?.data?.data) {
        exists = !response.data.data.isUnique;
      }
    } catch (e) {}

    return new Promise(  (resolve) => {
      resolve(!exists);
    });
  });

  const validationSchemaAdd = Yup.object().shape({
    [PRODUCT_FIELDS.NAME.api_name]: Yup.string()
      .required(allConstants.ERROR_MSG.REQUIRED_FIELD).nullable()
      .test({
        test: value => validator.isValidProductName(value),
        message: allConstants.ERROR_MSG.SHOULD_NOT_CONTAIN(['&'])
      })
      .test({
        test: value => {
          return Yup.string().checkIfProductExists(value, 'name', props?.productData?.[allConstants.PRODUCT_FIELDS.ID.api_name]);
        },
        message: allConstants.ERROR_MSG.SHOULD_BE_UNIQUE(PRODUCT_FIELDS.NAME.label)
      }),

    [PRODUCT_FIELDS.CATEGORY.api_name]: Yup.string()
      .required(allConstants.ERROR_MSG.REQUIRED_FIELD).nullable(),
    [PRODUCT_FIELDS.CODE.api_name]: Yup.string()
      .required(allConstants.ERROR_MSG.REQUIRED_FIELD).nullable()
      .test({
        test: value => {
          if(props.editMode) {
            return true;
          }
          return Yup.string().checkIfProductExists(value, 'code');},
        message: allConstants.ERROR_MSG.SHOULD_BE_UNIQUE(PRODUCT_FIELDS.CODE.label)
      }),
  });

  const formOptions = {
    resolver: yupResolver(validationSchemaAdd),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    criteriaMode:'all',
    defaultValues: {
      [PRODUCT_FIELDS.NAME.api_name]: props?.productData?.[PRODUCT_FIELDS.NAME.api_name] || '',
      [PRODUCT_FIELDS.CATEGORY.api_name]: props?.productData?.[PRODUCT_FIELDS.CATEGORY.api_name] || '',
      [PRODUCT_FIELDS.PRICE.api_name]: props?.productData?.[PRODUCT_FIELDS.PRICE.api_name] || null,
      [PRODUCT_FIELDS.CODE.api_name]: props?.productData?.[PRODUCT_FIELDS.CODE.api_name] || '',
      [PRODUCT_FIELDS.DESCRIPTION.api_name]: props?.productData?.[PRODUCT_FIELDS.DESCRIPTION.api_name] || '',
    }
  };
  const formMethods = useForm(formOptions);
  if(Utils.isDevEnv()) {
    window.productForm = formMethods;
  }


  const submitActions = {
    edit: {done: 'edited', action: 'edit'},
    create: {done: 'created', action: 'create'},
  };

  const handleSubmitResponse = (result, actionType)=> {
    if(result.status === 'success') {
      if(props?.closeModal) {
        props.closeModal();
      }
      toast.success(`Product successfully ${submitActions?.[actionType]?.done}. Please refresh page to see changes.`, {
        toastId: `success_${actionType}_product`,
        autoClose: 1000,
        onClose: () => {
          try{
            if(props.successAction) {
              props.successAction();
            }
          } catch (e){}

        }
      });
    } else{
      toast.error(`Error on product ${submitActions[actionType].create}. ${result?.message ? `Message: ${result.message}.` : ''}`, {
        toastId: `error_${submitActions[actionType].create}_product`,
      });
    }
  };

  const validateEditUniqueFields = async (value, fieldName)=> {
    if(fieldName === PRODUCT_FIELDS.NAME.api_name) {
      return Yup.string().checkIfProductExists(value, 'name', props?.productData?.[allConstants.PRODUCT_FIELDS.ID.api_name]);
    } else if(fieldName === PRODUCT_FIELDS.CODE.api_name) {
      return Yup.string().checkIfProductExists(value, 'code',   props?.productData?.[allConstants.PRODUCT_FIELDS.ID.api_name]);
    }
    return true;
  };

  const onSubmit = async ()=> {
    const data = formMethods.getValues();
    if(data?.[PRODUCT_FIELDS.CODE.api_name]) {
      data[PRODUCT_FIELDS.CODE.api_name] = data[PRODUCT_FIELDS.CODE.api_name].toUpperCase();
    }
    const authToken = await ApiUtils.getAccessToken();
    if(!authToken) {
      return;
    }

    if(props.editMode) {
      const dirtyFields = formMethods.formState.touchedFields;
      const cleanData = Utils.dirtyValues(dirtyFields, data);
      cleanData.id = props.productData[allConstants.PRODUCT_FIELDS.ID.api_name];
      let validatePromises = [];
      Object.entries(cleanData).forEach(([key, value]) => {
        validatePromises.push(validateEditUniqueFields(value, key).then((isValid)=> {
          if(!isValid) {
            formMethods.setError(key, {message: allConstants.ERROR_MSG.SHOULD_BE_UNIQUE(key)});
          }
        }));
      });

      Promise.all(validatePromises).then(async ()=> {
        if(Object.keys(formMethods.formState.errors).length === 0) {
          const response = await fetch(
            `${base_url.api}products/edit`,
            {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: authToken,
              },
              body: JSON.stringify(
                cleanData,
              ),
            }
          );
          let submitted = await response.json();
          handleSubmitResponse(submitted, submitActions.edit.action);
        }
      });

    } else {
      const response = await fetch(
        `${base_url.api}products/create`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify(
            data,
          ),
        }
      );
      let submitted = await response.json();
      handleSubmitResponse(submitted, submitActions.create.action);
    }
  };

  const codeValidation = async(e)=> {
    const code = e?.target?.value;
    if (!code) return;
    try {
      const authToken = await ApiUtils.getAccessToken();
      if (!authToken) {
        return;
      }
      const response = await fetch(
        `${base_url.api}products/validate-products-code/${code.toUpperCase()}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      let result = await response.json();
      if (result?.status === 'success') {
        const isUnique = result?.data?.isUnique;

        if (!isUnique) {
          setCanSubmit(false);
          formMethods.setError(PRODUCT_FIELDS.CODE.api_name, {message: allConstants.ERROR_MSG.PRODUCTS_CODE_NOT_UNIQUIE});
        } else {
          setCanSubmit(true);
          formMethods.clearErrors(PRODUCT_FIELDS.CODE.api_name);
        }
      }
    } catch(e){}
  };

  const onCancelAdd = (e)=> {
    e.preventDefault();
    formMethods.reset();
    props.closeModal();
  };
  const formInputDefaultProps = {
    viewMode: false,
    renderLabel: true,
  };

  return(
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}  method="POST">
          <Grid container xs={12}>
            <Box item xs={12} sm={12} className={clsx(classes.general_col)} sx={{
              width: '100%',
              '.MuiButtonBase-root-MuiMenuItem-root': {
                background: 'red',
                textTransform:'uppercase !important'
              }
            }}>
              <CustomFormBlock
                {...formInputDefaultProps}
                field={PRODUCT_FIELDS.NAME}
                defaultValue={props?.productData?.[PRODUCT_FIELDS.NAME.api_name]}
                fullWidth
                isEditable={true}
                preventCapitalize={true}
              />
              <Box sx={{
                '.MuiMenuItem-root': {
                  background: 'red',
                  textTransform:'uppercase !important'
                }
              }}>
                <CustomFormBlock
                  {...formInputDefaultProps}
                  field={PRODUCT_FIELDS.CATEGORY}
                  inputType={inputTypes.singleSelect}
                  defaultValue={props?.productData?.[PRODUCT_FIELDS.CATEGORY.api_name]}
                  options={Utils.getOptions(allConstants.PRODUCT_CATEGORIES_OPTIONS, null)}
                  fullWidth
                  isEditable={true}
                />
              </Box>
              <Box item xs={12} sm={12} className={clsx(classes.two_col_container)}>
                <CustomFormBlock
                  {...formInputDefaultProps}
                  field={PRODUCT_FIELDS.CODE}
                  defaultValue={props?.productData?.[PRODUCT_FIELDS.CODE.api_name]}
                  fullWidth
                  onBlur={codeValidation}
                  isEditable={true}
                />
                <CustomFormBlock
                  {...formInputDefaultProps}
                  field={PRODUCT_FIELDS.PRICE}
                  defaultValue={props?.productData?.[PRODUCT_FIELDS.PRICE.api_name]}
                  inputType={inputTypes.currency}
                  fullWidth
                  isEditable={true}
                />
              </Box>
              <Box item xs={12} sm={12} className={clsx(classes.general_col)} sx={{
                '.MuiFormControl-root': {
                  width: '100%'
                },
                '.MuiInputBase-root': {
                  width: '100%'
                },
                '.MuiInputBase-inputMultiline': {
                  width: '100% !important'
                }
              }}>
                <CustomFormBlock
                  {...formInputDefaultProps}
                  field={PRODUCT_FIELDS.DESCRIPTION}
                  defaultValue={props?.productData?.[PRODUCT_FIELDS.DESCRIPTION.api_name]}
                  multiline={true}
                  rowCount={3}
                  width={'100%'}
                  isEditable={true}
                />
              </Box>
            </Box>
            <Grid xs={12}>
              <Box display={'flex'} justifyContent={'center'}>
                <FormSubmitButtons cancelAction={onCancelAdd} submitText={'Save'} cancelText={'Cancel'}
                  disableSubmit={!canSubmit}
                />
              </Box>
            </Grid>

          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
ProductForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  productData: PropTypes.shape({
    [allConstants.PRODUCT_FIELDS.ID.api_name]:PropTypes.string.isRequired,
    [allConstants.PRODUCT_FIELDS.NAME.api_name]:PropTypes.string,
    [allConstants.PRODUCT_FIELDS.PRICE.api_name]: PropTypes.number,
    [allConstants.PRODUCT_FIELDS.CODE.api_name]:PropTypes.string,
    [allConstants.PRODUCT_FIELDS.CATEGORY.api_name]:PropTypes.oneOf(
      Object.values(allConstants.PRODUCT_CATEGORIES_OPTIONS).map((v)=> v.value)),
    [allConstants.PRODUCT_FIELDS.DESCRIPTION.api_name]:PropTypes.string,
  }),
  editMode: PropTypes.bool,
  successAction: PropTypes.func,
};

ProductForm.defaultProps = {
  productData: {
    [allConstants.PRODUCT_FIELDS.NAME.api_name]:undefined,
    [allConstants.PRODUCT_FIELDS.PRICE.api_name]: undefined,
    [allConstants.PRODUCT_FIELDS.CODE.api_name]:undefined,
    [allConstants.PRODUCT_FIELDS.CATEGORY.api_name]:undefined,
    [allConstants.PRODUCT_FIELDS.DESCRIPTION.api_name]:undefined,
    successAction: ()=>{}
  },
  editMode: false
};
export default ProductForm;
