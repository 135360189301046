const REBATE_FIELDS = {
  ID: {api_name: 'id', required: false, label: 'Rebate Program', display_label: 'rebate program'},
  REBATE_NAME: {api_name: 'rebateName', required: true, label: 'Rebate name', display_label: 'rebate name'},
  DATE_EXPIRED: {api_name: 'dateExpired', required: false, label: 'Date expired', display_label: 'date expiration'},
  OTHER_REBATE_NAME: {api_name: 'otherRebateName', required: true, label: 'Other rebate name', display_label: 'other rebate name'},
  CREATDE_BY: {api_name: 'createdBy', required: false, label: 'Created by', display_label: 'created by'},
  REBATE_PROGRAM: {api_name: 'rebateProgram', required: true, label: 'Rebate Program', display_label: 'rebate program'},
};


export {REBATE_FIELDS};
