import React from 'react';
import * as allConstants from '../../constants/allConstants';
import {useGridViewState} from '../../hooks/useGridViewState';
import {globalUseClasses} from '../../styles/globalClasses';
import usePagination from '../../hooks/usePagination';
import ListPageHeader from '../../pieces/listPageHeader';
import {ListViewHeader} from '../jobs/components/listViewHeader';
import NavBar from '../../pieces/navbar/navbar';
import {Box, ThemeProvider} from '@mui/material';
import DisplayListsPageWrapper from '../wrappers/displayListsPageWrapper';
import AllCitiesGrid from './allCitiesGrid';
import {useNavigate} from 'react-router-dom';
import {NewPermissionGate} from '../../permissions/permissionGate';
import {cityRules, CITY_ELEMENTS} from '../../permissions/cityAccessControl';
import myTheme from '../../styles/myStyles';

export const AllCitiesView = () => {
  const viewController = useGridViewState();
  const classes = globalUseClasses();
  const pagination = usePagination();
  const navigate = useNavigate();


  const viewCityHandler = (id)=> {
    navigate(`${id}${location.search}`);
  };

  const getHeaderComponent = ()=> {
    let title = 'All Cities';

    if(pagination.totalItemsCount) {
      title = `${title} (${pagination.totalItemsCount})`;
    }
    return (
      <ListViewHeader title={title}
        //gridEnum={JobGridEnum}
        pagination={pagination}
        //handleClick={handleClick}
        // menuController={menuController}
        // extendedFilters={shouldHideFilters() ? null : extendedFilters}
        viewController={viewController}
      />
    );
  };

  const headerComponent = <ListPageHeader  headComponent = {getHeaderComponent()}
    totalItems={pagination.totalItemsCount}
    addHandler={()=>navigate(allConstants.PATH.ADD_CITY)}
    showAddButton={NewPermissionGate({
      user: viewController.user,
      elementPermissions: cityRules[CITY_ELEMENTS.createCity]
    })}
  >
    {pagination.ItemsController}
  </ListPageHeader>;

  // if(viewController.blocked) return <BlockedPage/>;

  return (
    viewController.RenderElement(
      <>
        <NavBar/>
        <ThemeProvider theme={myTheme}>
          <Box className={classes.page}>

            <DisplayListsPageWrapper
              headerComponent={headerComponent}>
              <AllCitiesGrid
                user={viewController.user}
                viewController={viewController}
                pagination={pagination}
                viewCityHandler={viewCityHandler}
              />
              {pagination.PageController}

            </DisplayListsPageWrapper>
          </Box>
        </ThemeProvider>
      </>
    )
  );
};
