import React  from 'react';
import {Box, Button, Typography} from '@mui/material';
//import {useGridViewState} from '../hooks/useGridViewState';
import PropTypes from 'prop-types';
import AddButton from './addButton';
import Spacer from './spacer';

const ListPageHeader = (props)=> {

  let header = props.header;
  if(props.totalItems) {
    header = `${header} (${props.totalItems})`;
  }
  return (
    <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
      {props.header && <Box>
        <Typography variant={'h4'}>{header}</Typography>
      </Box>}
      <Box display={'flex'}>
        {props.headComponent && props.headComponent}
        {/* {viewController.isLoading && <Box display='flex' justifyContent='center' alignItems='center'>
                <CircularProgress variant="indeterminate"  color="success" />
                <Typography color="red" variant="h6">
                    Please wait!
                </Typography>                
              </Box>} */}
        {props.additionalButtons && props.additionalButtons.length > 0 &&
        <Box display={'flex'} alignItems={'center'} >
          <Spacer x={4}  />
          {props.additionalButtons.map((button, index) => {
            return <><Spacer x={3} /><Button key={index+button.label}
              onClick={button.onClick}
              variant={button.variant}
              color={button.color}
              sx={{
                textTransform: 'uppercase',
                lineHeight: '16px',  
              }} 
            >{button.label}
            </Button></>;
          })
          }
        </Box>}
      </Box>
      {/* {props.showCustomView && 
        <Box display={'flex'} alignItems={'left'}>
          <div>My views</div>
          <AddButton action={props.createCustomViewHandler} />
        </Box>
      } */}
      <Box display={'flex'} alignItems={'center'}>
        {props.children}
        {props.showAddButton && <AddButton action={props.addHandler}/>}
      </Box>
    </Box>

  );
};

ListPageHeader.propTypes = {
  header: PropTypes.string,
  headComponent: PropTypes.node,
  addHandler: PropTypes.func,
  showAddButton: PropTypes.bool,
  totalItems: PropTypes.number,
  additionalButtons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    variant: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  })),
};

ListPageHeader.defaultProps = {
  header: '',
  headComponent: null,
  addHandler: null,
  showAddButton: false,
  totalItems: null,
  additionalButtons: [],
};

export default ListPageHeader;
