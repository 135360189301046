import React, {useState} from 'react';
import * as allConstants from '../../../../../constants/allConstants';
import {Box,Typography,} from '@mui/material';
import {fieldBank} from '../fieldBank';
import mechanicalUtils from '../mechUtils';
import * as Yup from 'yup';
import SystemFormWrapper from './systemFormWrapper';


const CoolingSystem = (props)=> {
  const [selected, setSelected] = useState();
  const MECH_FIELDS = allConstants.JOB_FIELDS.MECHANICAL;
  const SHARED = allConstants.JOB_FIELDS.SHARED;
  const EQUIPMENT = allConstants.EQUIPMENT;
  const system = allConstants.JOB_FIELDS.MECHANICAL.COOLING_SYSTEM.api_name;
  const options = allConstants.SYSTEM_CONTENT[system];
  const filterTons = {filterOptions: (opt)=> opt.value !== allConstants.TONS_OPTIONS.BASE['4.5'].value};

  const equipmentMap = {
    [EQUIPMENT.CONDENSER.api_name]: {
      name: EQUIPMENT.CONDENSER.api_name,
      fields: [
        {...fieldBank[MECH_FIELDS.TONS.api_name],...filterTons},
        fieldBank[SHARED.ACTION.api_name],
        fieldBank[SHARED.CONVERT_FROM.api_name],
        fieldBank[SHARED.CONVERT_TO.api_name],
        fieldBank[SHARED.LOCATION.api_name],
        fieldBank[MECH_FIELDS.SEER.api_name],
        fieldBank[MECH_FIELDS.MAKE.api_name],
        fieldBank[MECH_FIELDS.MODEL.api_name],
        fieldBank[MECH_FIELDS.NEED_ELECTRICAL.api_name]
      ]
    },
    [EQUIPMENT.HEAT_PUMP_COND.api_name]: {
      //image: HeatPumpCondenser
      name: EQUIPMENT.HEAT_PUMP_COND.api_name,
      fields: [
        {...fieldBank[MECH_FIELDS.TONS.api_name],...filterTons},
        fieldBank[SHARED.ACTION.api_name],
        fieldBank[SHARED.CONVERT_FROM.api_name],
        fieldBank[SHARED.CONVERT_TO.api_name],
        fieldBank[SHARED.LOCATION.api_name],
        fieldBank[MECH_FIELDS.SEER.api_name],
        fieldBank[MECH_FIELDS.MAKE.api_name],
        fieldBank[MECH_FIELDS.MODEL.api_name],
        fieldBank[MECH_FIELDS.NEED_ELECTRICAL.api_name]
      ]
    },
    [EQUIPMENT.COIL.api_name]: {
      //image: IndoorCoil
      name: EQUIPMENT.COIL.api_name,
      fields: [
        {...fieldBank[MECH_FIELDS.TONS.api_name],...filterTons},
        fieldBank[MECH_FIELDS.SEER.api_name],
        fieldBank[MECH_FIELDS.MAKE.api_name],
        fieldBank[MECH_FIELDS.MODEL.api_name]
      ]
    },
    [EQUIPMENT.MINI_SPLIT_DUCTED.api_name]: {
      //image: MsDucted
      name: EQUIPMENT.MINI_SPLIT_DUCTED.api_name,
      fields: [
        {...fieldBank[`${MECH_FIELDS.TONS.api_name}-minisplit`],...filterTons},
        fieldBank[SHARED.ACTION.api_name],
        fieldBank[SHARED.CONVERT_FROM.api_name],
        fieldBank[SHARED.CONVERT_TO.api_name],
        fieldBank[SHARED.LOCATION.api_name],
        fieldBank[MECH_FIELDS.SEER.api_name],
        fieldBank[MECH_FIELDS.MAKE.api_name],
        fieldBank[MECH_FIELDS.MODEL.api_name],
        fieldBank[MECH_FIELDS.NEED_ELECTRICAL.api_name],
        fieldBank[MECH_FIELDS.AH_QTY.api_name],
        fieldBank[MECH_FIELDS.SUPPLY.api_name],
        fieldBank[MECH_FIELDS.RETURN.api_name],
      ]
    },
    [EQUIPMENT.MINI_SPLIT.api_name]: {
      //image: MsDucted
      name: EQUIPMENT.MINI_SPLIT.api_name,
      fields: [
        {...fieldBank[`${MECH_FIELDS.TONS.api_name}-minisplit`],...filterTons},
        fieldBank[SHARED.ACTION.api_name],
        fieldBank[SHARED.CONVERT_FROM.api_name],
        fieldBank[SHARED.CONVERT_TO.api_name],
        fieldBank[SHARED.LOCATION.api_name],
        fieldBank[MECH_FIELDS.SEER.api_name],
        fieldBank[MECH_FIELDS.MAKE.api_name],
        fieldBank[MECH_FIELDS.MODEL.api_name],
        fieldBank[MECH_FIELDS.NEED_ELECTRICAL.api_name],
        fieldBank[MECH_FIELDS.AH_QTY.api_name],
      ]
    },
  };

  const validationSchema =Yup.object().shape({
    [EQUIPMENT.CONDENSER.api_name]: Yup.array().of(Yup.object().shape({
      fields: (()=>{
        const {shape, edges} = mechanicalUtils.validateIfAnyInRowIsNotEmpty(
          [MECH_FIELDS.TONS.api_name, SHARED.ACTION.api_name, SHARED.LOCATION.api_name],
          equipmentMap[EQUIPMENT.CONDENSER.api_name].fields);
        return Yup.array().of(Yup.object().shape(shape, edges));
      })(),
    })),
    [EQUIPMENT.HEAT_PUMP_COND.api_name]: Yup.array().of(Yup.object().shape({
      fields: (()=>{
        const {shape, edges} = mechanicalUtils.validateIfAnyInRowIsNotEmpty(
          [MECH_FIELDS.TONS.api_name, SHARED.ACTION.api_name, SHARED.LOCATION.api_name],
          equipmentMap[EQUIPMENT.HEAT_PUMP_COND.api_name].fields);
        return Yup.array().of(Yup.object().shape(shape, edges));
      })(),

    })),
    [EQUIPMENT.MINI_SPLIT.api_name]: Yup.array().of(Yup.object().shape({
      fields: (()=>{
        const {shape, edges} = mechanicalUtils.validateIfAnyInRowIsNotEmpty(
          [MECH_FIELDS.TONS.api_name, SHARED.ACTION.api_name, SHARED.LOCATION.api_name, MECH_FIELDS.AH_QTY.api_name],
          equipmentMap[EQUIPMENT.MINI_SPLIT.api_name].fields);
        return Yup.array().of(Yup.object().shape(shape, edges));
      })(),
    })),
    [EQUIPMENT.MINI_SPLIT_DUCTED.api_name]: Yup.array().of(Yup.object().shape({
      fields: (()=>{
        const {shape, edges} = mechanicalUtils.validateIfAnyInRowIsNotEmpty(
          [MECH_FIELDS.TONS.api_name, SHARED.ACTION.api_name, SHARED.LOCATION.api_name,
            MECH_FIELDS.AH_QTY.api_name, MECH_FIELDS.SUPPLY.api_name, MECH_FIELDS.RETURN.api_name],
          equipmentMap[EQUIPMENT.MINI_SPLIT_DUCTED.api_name].fields);
        return Yup.array().of(Yup.object().shape(shape, edges));
      })(),
    })),
  });

  const isAnySelected = ()=> {
    if(!selected || selected.length === 0) return false;
    return selected.filter((s)=>s).length !== 0;
  };
  return(
    <>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        {SystemFormWrapper({
          ...props,
          options: options,
          system: system,
          equipmentMap: equipmentMap,
          validationSchema: validationSchema,
          setSelectedToParent: setSelected,
          headerNote: isAnySelected() ? <Box display={'flex'} alignItems={'center'} sx={{height: '37px'}}>
            <Typography variant={'body1'} sx={{
              fontSize: {sx: '14px', md: '16px'},
              color: 'red'
            }}>
                An aerial view will appear on the next page</Typography>
          </Box> : null,

          ...props
        })}
      </Box>
    </>
  );
};

export default CoolingSystem;
