/* eslint  no-unused-vars: 0 */
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

let hostname = window.location.hostname;

// Everything defaults to staging

//let base_hostname = `api.${process.en//v.REACT_APP_PROD_PATH}.ipermitpro.com`;
//let base_hostname = process.env.REACT_APP_API_URL || 'api.prod.ipermitpro.com';

//let base_hostname  = "https://api.staging.ipermitpro.com";
let base_hostname =  'https://api.prod.ipermitpro.com';

//let base_hostname =  'http://localhost:3000';

let api = `${base_hostname}/api/`;

console.log('base-hostname=>' + base_hostname);
//console.log('API URL=>' + process.env.REACT_APP_APIURL);
//console.log('PRO //path=' + process.env.REACT_APP_PROD_PATH);

// if(process.env.NODE_ENV === 'development') {
//   base_hostname = 'api.dev.ipermitpro.com';
// }

let mock_base_hostname = 'localhost:4000';


const mock_api = `http://${mock_base_hostname}/`;

const AWS_URL_PREFIX =  ()=> {
  const env = 'prod';
  //let env = process.env.NODE_ENV === 'production' ? 'prod' : 'dev';
  return `https://analytics.${env}.api.ipermiteraters.com`;
};

let base_url = {
  hostname: base_hostname,
  api: api,
  mock_api,
  aws_api: AWS_URL_PREFIX(),
};

const urlSearchParams = {
  duplicateOfId: 'duplicateOfId',
  draftId: 'draftId',
  stateLicenseNumber: 'stateLicenseNumber',
  companyId: 'companyId',
  completeOnboarding: 'completeOnboarding',
  redirect: 'redirect',
  invite: 'invite',
  openTab: 'openTab',
  module: 'module',
  moduleName: 'moduleName',
  submodule: 'submodule',
  view: 'view',
  failedInspections: 'failedInspections',
  userId: 'userId',
  raterId: 'raterId',
  timeline: 'timeline',
  jobId: 'job',
  showSameAddressJobs: 'showSameAddressJobs'
};

export default base_url;
export {urlSearchParams};
