import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../constants/allConstants';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import CustomFormBlock from '../../pieces/inputs/formBlock';
import {Box, Grid} from '@mui/material';
import FormSubmitButtons from '../../pieces/formSubmitButtons';
import validator from '../../utils/validators/validator';
import {inputTypes} from '../../pieces/inputs/formInput';
import ApiUtils from '../../utils/apiUtils';
import base_url from '../../baseUrls';
import Utils from '../../utils/utils';
import clsx from 'clsx';
import {globalUseClasses} from '../../styles/globalClasses';
import {toast} from 'react-toastify';
import axios from 'axios';

const TAG_FIELDS = allConstants.TAG_FIELDS;

const TagForm = (props)=> {
  
  const classes = {...globalUseClasses()};
  const [canSubmit, setCanSubmit] = useState(true);

  Yup.addMethod(Yup.string, 'checkIfTagExists', async function ( value, key, id ) {
    let exists = false;
    if(!value) return false;
    try{
      const authToken = await ApiUtils.getAccessToken();
      let url = `${base_url.api}tags/tag-is-unique?${key}=${value}`;
      if(id) {
        url += `&id=${id}`;
      }
      const response = await axios.get(url, {
        headers: {Authorization: authToken}});
      if(response?.data?.data) {
        exists = !response.data.data.isUnique;
      }
    } catch (e) {}

    return new Promise(  (resolve) => {
      resolve(!exists);
    });
  });

  const validationSchemaAdd = Yup.object().shape({
    [TAG_FIELDS.TAG_NAME.api_name]: Yup.string()
      .required(allConstants.ERROR_MSG.REQUIRED_FIELD).nullable()
      .test({
        test: value => validator.isValidTagName(value),
        message: allConstants.ERROR_MSG.SHOULD_NOT_CONTAIN(['&'])
      })
      .test({
        test: value => {
          return Yup.string().checkIfTagExists(value, 'tagName', props?.tagData?.[TAG_FIELDS.ID.api_name]);
        },
        message: allConstants.ERROR_MSG.SHOULD_BE_UNIQUE(TAG_FIELDS.TAG_NAME.label)
      }),
  });

  const formOptions = {
    resolver: yupResolver(validationSchemaAdd),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    criteriaMode:'all',
    defaultValues: {
      [TAG_FIELDS.TAG_NAME.api_name]: props?.tagData?.[TAG_FIELDS.TAG_NAME.api_name] || '',
    }
  };
  const formMethods = useForm(formOptions);
  if(Utils.isDevEnv()) {
    window.tagForm = formMethods;
  }

  const submitActions = {
    edit: {done: 'edited', action: 'edit'},
    create: {done: 'created', action: 'create'},
  };

  const handleSubmitResponse = (result, actionType)=> {
    if(result.status === 'success') {
      if(props?.closeModal) {
        props.closeModal();
      }
      toast.success(`Tag successfully ${submitActions?.[actionType]?.done}. Please refresh page to see changes.`, {
        toastId: `success_${actionType}_tag`,
        autoClose: 1000,
        onClose: () => {
          try{
            if(props.successAction) {
              props.successAction();
            }
          } catch (e){}
        }
      });
    } else{
      toast.error(`Error on tag ${submitActions[actionType].create}. ${result?.message ? `Message: ${result.message}.` : ''}`, {
        toastId: `error_${submitActions[actionType].create}_tag`,
      });
    }
  };

  const validateEditUniqueFields = async (value, fieldName)=> {
    if(fieldName === TAG_FIELDS.TAG_NAME.api_name) {
      return Yup.string().checkIfTagExists(value, 'tagName', props?.tagData?.[TAG_FIELDS.ID.api_name]);
    }
    return true;
  };

  const onSubmit = async ()=> {
    const data = formMethods.getValues();
    const authToken = await ApiUtils.getAccessToken();
    if(!authToken) {
      return;
    }

    if(props.editMode) {
      const dirtyFields = formMethods.formState.touchedFields;
      const cleanData = Utils.dirtyValues(dirtyFields, data);
      cleanData.id = props.tagData[TAG_FIELDS.ID.api_name];
      let validatePromises = [];
      Object.entries(cleanData).forEach(([key, value]) => {
        validatePromises.push(validateEditUniqueFields(value, key).then((isValid)=> {
          if(!isValid) {
            formMethods.setError(key, {message: allConstants.ERROR_MSG.SHOULD_BE_UNIQUE(key)});
          }
        }));
      });

      Promise.all(validatePromises).then(async ()=> {
        if(Object.keys(formMethods.formState.errors).length === 0) {
          const response = await fetch(
            `${base_url.api}tags/edit`,
            {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: authToken,
              },
              body: JSON.stringify(
                cleanData,
              ),
            }
          );
          let submitted = await response.json();
          handleSubmitResponse(submitted, submitActions.edit.action);
        }
      });

    } else {
      const response = await fetch(
        `${base_url.api}tags/create`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify(
            data,
          ),
        }
      );
      let submitted = await response.json();
      handleSubmitResponse(submitted, submitActions.create.action);
    }
  };


  const onCancelAdd = (e)=> {
    e.preventDefault();
    formMethods.reset();
    props.closeModal();
  };
  const formInputDefaultProps = {
    viewMode: false,
    renderLabel: true,
  };

  return(
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}  method="POST">
          <Grid container xs={12}>
            <Box item xs={12} sm={12} className={clsx(classes.general_col)} sx={{
              width: '100%',
              '.MuiButtonBase-root-MuiMenuItem-root': {
                background: 'red',
                textTransform:'uppercase !important'
              }
            }}>
              <CustomFormBlock
                {...formInputDefaultProps}
                field={TAG_FIELDS.TAG_NAME}
                defaultValue={props?.tagData?.[TAG_FIELDS.TAG_NAME.api_name]}
                fullWidth
                isEditable={true}
                preventCapitalize={true}
              />
            </Box>
            <Grid xs={12}>
              <Box display={'flex'} justifyContent={'center'}>
                <FormSubmitButtons cancelAction={onCancelAdd} submitText={'Save'} cancelText={'Cancel'}
                  disableSubmit={!canSubmit}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
TagForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  tagData: PropTypes.shape({
    [TAG_FIELDS.ID.api_name]:PropTypes.string.isRequired,
    [TAG_FIELDS.TAG_NAME.api_name]:PropTypes.string,
  }),
  editMode: PropTypes.bool,
  successAction: PropTypes.func,
};

TagForm.defaultProps = {
  tagData: {
    [TAG_FIELDS.TAG_NAME.api_name]:undefined,
    successAction: ()=>{}
  },
  editMode: false
};
export default TagForm;
