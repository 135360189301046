import React, {useState, useCallback} from 'react';
import {ThemeProvider, Box} from '@mui/material';
import NavBar from '../../pieces/navbar/navbar';
import {NewPermissionGate} from '../../permissions/permissionGate';
import myTheme from '../../styles/myStyles';
import DisplayListsPageWrapper from '../wrappers/displayListsPageWrapper';
import ListPageHeader from '../../pieces/listPageHeader';
import {ListViewHeader} from '../jobs/components/listViewHeader';
import usePagination from '../../hooks/usePagination';
import {useGridViewState} from '../../hooks/useGridViewState';
import {globalUseClasses} from '../../styles/globalClasses';
import {STATE_ELEMENTS, stateRules} from '../../permissions/stateAccessControl';
import AllStatesGrid from './allStatesGrid';
import StateForm from './stateForm';
import TagModal from '../../pieces/modals/tagModal';
import ConfirmDelete from '../../pieces/modals/confirmDelete';
import base_url from '../../baseUrls';

const AllStatesView = () => {
  const viewController = useGridViewState();
  const classes = globalUseClasses();
  const pagination = usePagination();

  const [selectedState, setSelectedState] = useState(undefined);
  const [showAddStatePopup, setShowAddStatePopup] = useState(false);
  const [showEditStatePopup, setShowEditStatePopup] = useState(false);
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleAddStateOpen = useCallback(() => setShowAddStatePopup(true), []);  
  const handleAddStateClose = () => setShowAddStatePopup(false);
  const handleEditStateClose = () => setShowEditStatePopup(false);
  const handleConfirmDeletePopupClose = () => setShowConfirmDeletePopup(false);


  const editStateHandler = (data)=> {

    //console.log('inside editStatehandler');
    //console.log(data);

    setSelectedState(data);
    setShowEditStatePopup(true);
  };

  const editStateCancel = ()=> {
    setSelectedState(undefined);
    setShowEditStatePopup(false);
  };

  const handleSuccess = ()=> {
    if(showAddStatePopup || showEditStatePopup || showConfirmDeletePopup) {
      window.location.reload();
    }
  };

  const deleteStateHandler = (data)=> {    
    setSelectedState(data);
    setShowConfirmDeletePopup(true);
  };

  const getDeleteURL = ()=> {
    if(!selectedState) return null;
    return `${base_url.api}states/delete`;
  };

  const deleteCancelHandler = ()=> {
    setSelectedState(undefined);
    setShowConfirmDeletePopup(false);
  };

  const getHeaderComponent = ()=> {
    let title = 'All State';

    if(pagination.totalItemsCount) {
      title = `${title} (${pagination.totalItemsCount})`;
    }
    return (
      <ListViewHeader title={title}
        //gridEnum={JobGridEnum}
        pagination={pagination}
        //handleClick={handleClick}
        // menuController={menuController}
        // extendedFilters={shouldHideFilters() ? null : extendedFilters}
        viewController={viewController}
      />
    );
  };
  const headerComponent = <ListPageHeader 
    headComponent = {getHeaderComponent()}
    addHandler={handleAddStateOpen}
    totalItems={pagination.totalItemsCount}
    showAddButton = {NewPermissionGate({
      user: viewController.user,
      elementPermissions: stateRules[STATE_ELEMENTS.addState],
    })}
  >
    {pagination.ItemsController}
  </ListPageHeader>;

  return (
    viewController.RenderElement(
      <>
        <NavBar/>
        <ThemeProvider theme={myTheme}>
          <Box className={classes.page}>
            <TagModal open={showEditStatePopup}
              handleClose={handleEditStateClose}
              header={'Edit State'}
              preventScroll={false}
            >
              <StateForm closeModal={handleEditStateClose}
                editMode={true}
                successAction={handleSuccess}
                stateData={selectedState}/>
            </TagModal>

            <TagModal open={showAddStatePopup}
              handleClose={handleAddStateClose}
              header={'Create State'}
              preventScroll={false}
            >
              <StateForm closeModal={handleAddStateClose}
                successAction={handleSuccess}
                editMode={false}/>
            </TagModal >

            <ConfirmDelete deleteUrl={getDeleteURL()}
              open={showConfirmDeletePopup}
              submitDeleteAction={handleConfirmDeletePopupClose}
              handleCancel={deleteCancelHandler}
              successAction={handleSuccess}
              idToDelete={selectedState?.id}
              entityName={'state'}
              recordName={selectedState ? selectedState.name : ''}
            />

            <DisplayListsPageWrapper
              headerComponent={headerComponent}>
              <AllStatesGrid 
                editStateHandler={editStateHandler}
                editStateCancel={editStateCancel}
                viewController={viewController}
                deleteStateHandler={deleteStateHandler}
                deleteStateCancel={deleteCancelHandler}

                // setBlocked={viewController.setBlocked}
                pagination={pagination}
              />
              {pagination.PageController}

            </DisplayListsPageWrapper>
          </Box>
        </ThemeProvider>
      </>
    )
  );
};

export default AllStatesView;
