import React, {useState, useEffect, useRef, useMemo} from 'react';
import {useFormContext, useFieldArray} from 'react-hook-form';
import {Box, Card, Grid, List, ListItem} from '@mui/material';
import PropTypes from 'prop-types';
import * as allConstants from '../../constants/allConstants';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import CustomFormBlock from '../../pieces/inputs/formBlock';
import FormSubmitButtons from '../../pieces/formSubmitButtons';
import {AddRowButton, DeleteEquipmentRowButton,variants} from '../../pieces/addDeleteEquipmentRowButtons';
import Spacer from '../../pieces/spacer';
import {useSubform} from '../../hooks/useSubform';
import validator from '../../utils/validators/validator';
import {inputTypes} from '../../pieces/inputs/formInput';
import ApiUtils from '../../utils/apiUtils';
import base_url from '../../baseUrls';
import Utils from '../../utils/utils';
import clsx from 'clsx';
import {globalUseClasses} from '../../styles/globalClasses';
import {toast} from 'react-toastify';
import axios from 'axios';

const {STATE_FIELDS , REGION_FIELDS} = allConstants;

const StateForm = (props) => {

  const classes = {...globalUseClasses()};
  const [canSubmit, setCanSubmit] = useState(true);
  const [regionChanged, setRegionChanged] = useState(false);

  const gridTemplateColumns =  '300px 150px';
  const gap = '4px';

  const regionCollectionName = useMemo(()=> STATE_FIELDS.REGIONS.api_name, []);

  const validationSchemaAdd = Yup.object().shape({
    [STATE_FIELDS.NAME.api_name]: Yup.string()
      .required(allConstants.ERROR_MSG.REQUIRED_FIELD).nullable()
      .test({
        test: value => validator.isValidStateName(value),
        message: allConstants.ERROR_MSG.SHOULD_NOT_CONTAIN(['&'])
      })
      .test({
        test: value => {
          return Yup.string().checkIfStateExists(value, 'name', props?.stateData?.[STATE_FIELDS.ID.api_name]);
        },
        message: allConstants.ERROR_MSG.SHOULD_BE_UNIQUE(STATE_FIELDS.NAME.label)
      }),
  });

  const validationSchema = useMemo(()=>Yup.object().shape({
    [regionCollectionName]: Yup.array().of(Yup.object().shape({
      [REGION_FIELDS.NAME.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD).nullable(),
    }))
  }), []);

  const formOptions = {
    resolver: yupResolver(validationSchemaAdd),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    criteriaMode:'all',
    defaultValues: {
      [STATE_FIELDS.NAME.api_name]: props?.stateData?.[STATE_FIELDS.NAME.api_name] || '',
      [STATE_FIELDS.ID.api_name]: props?.stateData?.[STATE_FIELDS.ID.api_name] || null,
    }
  };
  const formMethods = useForm(formOptions);

  //const getStateData = ()=> {
  //   const initialData = props.stateData || {};
  //   return {...initialData, ...formMethods.getValues()};
  // };

  // const getDefaultValue = (field, parent = null) => {
  //   if (parent) {
  //     return props.stateData?.[parent]?.[field];
  //   }
  //   return props.stateData?.[field];
  // };

  const commonFieldProps = {
    fullWidth: true,
    maxWidth: '100%',
  };

 
  const regionRow = {
    [REGION_FIELDS.NAME.api_name]: '',
    [REGION_FIELDS.ID.api_name]:null,
  };

  const {fields, append, remove} = useFieldArray({
    control: formMethods.control,
    name: regionCollectionName,
  });

  const regionSubformDefaultProps = (index) => ({
    defaultValue: '',
    isEditable: true,
    nested: true,
    fullWidth: true,
    parent: `${regionCollectionName}[${index}]`
  });

  // const deleteRegionRow = (index) => {
  //   try {
  //     const regionList = [...formMethods.getValues()?.[STATE_FIELDS.REGIONS.api_name]];

  //     regionList.splice(index, 1);

  //     console.log('new region list');
  //     console.log(regionList);

  //     formMethods.setValue(STATE_FIELDS.REGIONS.api_name, regionList);

  
  //   } catch(e){

  //   }
  // };


  useEffect(()=> {

    formMethods.setValue(STATE_FIELDS.REGIONS.api_name, []);

    for (var i=0; i < props.stateData?.[STATE_FIELDS.REGIONS.api_name]?.length??0; i++) {

      const row = {
        [REGION_FIELDS.NAME.api_name] : props.stateData?.[STATE_FIELDS.REGIONS.api_name][i][REGION_FIELDS.NAME.api_name],
        [REGION_FIELDS.ID.api_name] :  props.stateData?.[STATE_FIELDS.REGIONS.api_name][i][REGION_FIELDS.ID.api_name]
      };
      append({...row});
    }
  }, [props.stateData?.name]);


  Yup.addMethod(Yup.string, 'checkIfStateExists', async function ( value, key, id ) {
    let exists = false;

    if(!value) return false;
    try{

      const authToken = await ApiUtils.getAccessToken();
      let url = `${base_url.api}states/state-is-unique?${key}=${value}`;
      if(id) {
        url += `&id=${id}`;
      } 
      const response = await axios.get(url, {
        headers: {Authorization: authToken}});
      if(response?.data?.data) {
        exists = !response.data.data.isUnique;
      }
    } catch (e) {}

    return new Promise(  (resolve) => {
      resolve(!exists);
    });
  });

  const validateEditUniqueFields = async (value, fieldName)=> {
    if(fieldName === STATE_FIELDS.NAME.api_name) {

      console.log('check if state exits');
      return Yup.string().checkIfStateExists(value, 'name', props?.stateData?.[STATE_FIELDS.ID.api_name]);
    }
    return true;
  };


  if(Utils.isDevEnv()) {
    window.stateView = formMethods;
  }

  const submitActions = {
    edit: {done: 'edited', action: 'edit'},
    create: {done: 'created', action: 'create'},
  };

  const handleSubmitResponse = (result, actionType)=> {
    if(result.status === 'success') {
      if(props?.closeModal) {
        props.closeModal();
      }
      toast.success(`State successfully ${submitActions?.[actionType]?.done}. Please refresh page to see changes.`, {
        toastId: `success_${actionType}_state`,
        autoClose: 1000,
        onClose: () => {
          try{
            if(props.successAction) {
              props.successAction();
            }
          } catch (e){}
        }
      });
    } else{
      toast.error(`Error on state ${submitActions[actionType].create}. ${result?.message ? `Message: ${result.message}.` : ''}`, {
        toastId: `error_${submitActions[actionType].create}_state`,
      });
    }
  };


  const onSubmit = async ()=> {
    const data = formMethods.getValues();

    // console.log('new form data');
    // console.log(data);

    const authToken = await ApiUtils.getAccessToken();
    if(!authToken) {
      return;
    }
    if(props.editMode) {
      const dirtyFields = formMethods.formState.touchedFields;
      const cleanData = Utils.dirtyValues(dirtyFields, data);

      // console.log('dirtyFields');
      // console.log(dirtyFields);
      // console.log('clean data');
      // console.log(cleanData);

      cleanData.id = props.stateData?.[STATE_FIELDS.ID.api_name];

      if (regionChanged) {
        cleanData.regions = data[regionCollectionName];
      }

      let validatePromises = [];
      Object.entries(cleanData).forEach(([key, value]) => {
        validatePromises.push(validateEditUniqueFields(value, key).then((isValid)=> {
          if(!isValid) {
            formMethods.setError(key, {message: allConstants.ERROR_MSG.SHOULD_BE_UNIQUE(key)});
          }
        }));
      });

      Promise.all(validatePromises).then(async ()=> {
        if(Object.keys(formMethods.formState.errors).length === 0) {

          const response = await fetch(
            `${base_url.api}states/edit`,
            {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: authToken,
              },
              body: JSON.stringify(
                cleanData,
              ),
            }
          );
          let submitted = await response.json();
          handleSubmitResponse(submitted, submitActions.edit.action);
        }
      });

    } else {
      const response = await fetch(
        `${base_url.api}states/create`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify(
            data,
          ),
        }
      );
      let submitted = await response.json();
      handleSubmitResponse(submitted, submitActions.create.action);
    }
  };

  const onCancelAdd = (e)=> {
    e.preventDefault();
    formMethods.reset();
    props.closeModal();
  };
  const formInputDefaultProps = {
    viewMode: false,
    renderLabel: true,
  };


  return(
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}  method="POST">
          <Grid container xs={12}>
            <Box item xs={12} sm={12} className={clsx(classes.general_col)} sx={{
              width: '100%',
              '.MuiButtonBase-root-MuiMenuItem-root': {
                background: 'red',
                textTransform:'uppercase !important',
                padingTop: '0px',
                paddingBottom: '0px'
              }
            }}>
              <CustomFormBlock
                {...formInputDefaultProps}
                field={STATE_FIELDS.NAME}
                defaultValue={props?.stateData?.[STATE_FIELDS.NAME.api_name]}
                fullWidth={true}
                isEditable={true}
                preventCapitalize={true}
              />
              <CustomFormBlock
                {...formInputDefaultProps}
                field={STATE_FIELDS.ID}
                defaultValue={props?.stateData?.[STATE_FIELDS.ID.api_name]}
                isEditable={false}
                hide={true}
              />
            </Box>
            <List>
              {fields.map((item, index) => (
                <ListItem key={item.id+index} sx={{
                  paddingX: 0,
                  paddingY: 0,
                  display: 'grid',
                  gridTemplateColumns: gridTemplateColumns,
                  gap: gap,
                  alignItems: 'flex-start',
                  '& .MuiInputBase-inputMultiline': {
                    width: '100% !important',
                  },
                }}>
                  <CustomFormBlock 
                    {...formInputDefaultProps}
                    //{...commonFieldProps}
                    {...regionSubformDefaultProps(index)}
                    inputType={inputTypes.text}
                    isEditable={true}
                    fullwidth
                    labelSecondary={true}
                    field={REGION_FIELDS.NAME}
                    preventCapitalize={true}
                  />
                  <CustomFormBlock 
                    inputType={inputTypes.number}
                    isEditable={false}
                    labelSecondary={true}
                    field={REGION_FIELDS.ID}
                    hide={true}
                  />
                  <DeleteEquipmentRowButton eqName={'regions'} index={index+1}
                    extraStyles={{alignSelf: 'baseline',
                      top: '6px',
                      position: 'relative',
                      padding: '0'
                    }}
                    deleteAction={(idx) => { 
                      remove(idx-1);
                      
                      setRegionChanged(true);
                    }}
                  />
                </ListItem>
              ))}
            </List>
            <Box>
                <AddRowButton eqName={'regions'} variant={variants.textWithIcon} text="Add Region"
                  addAction={() => {
                    append({...regionRow}) 
                    setRegionChanged(true);
                  }}
                />
            </Box>
            <Grid xs={12}>
              <Box display={'flex'} justifyContent={'center'}>
                <FormSubmitButtons cancelAction={onCancelAdd} submitText={'Save'} cancelText={'Cancel'}
                  disableSubmit={!canSubmit}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

StateForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  stateData: PropTypes.shape({
    [STATE_FIELDS.ID.api_name]:PropTypes.string.isRequired,
    [STATE_FIELDS.NAME.api_name]:PropTypes.string,
  }),
  editMode: PropTypes.bool,
  successAction: PropTypes.func,
};

StateForm.defaultProps = {
  stateData: {
    [STATE_FIELDS.NAME.api_name]:undefined,
    successAction: ()=>{}
  },
  editMode: false
};
export default StateForm;
